import {
  Button,
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import EmployeeAvatar from 'components/@home/EmployeeAvatar';
import isEqual from 'lodash/isEqual';
import { bool, func } from 'prop-types';
import React, { memo } from 'react';
import { I18n } from 'react-redux-i18n';
import channelShape from 'shapes/channel';
import Label from './Label';
import LastMessage from './LastMessage';

const useStyles = makeStyles(theme => ({
  root: {
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  disabled: {
    '&:after': {
      top: 0,
      left: 0,
      content: `''`,
      position: 'absolute',
      backgroundColor: theme.palette.primary.ultraLight,
      opacity: 0.8,
      width: '100%',
      height: '100%',
    },
  },
  avatarContainer: {
    padding: 16,
  },
  primaryText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  lastMessage: {
    textOverflow: 'ellipsis',
  },
  channelName: {
    width: '210px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  timeCaption: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 84,
  },
  time: {
    minWidth: 54,
  },
  itemText: {
    padding: '10px 16px 10px 0',
    maxHeight: '100%',
  },
  joinButton: {
    position: 'absolute',
    left: '50%',
    zIndex: 2,
  },
  last: {
    '& $itemText': {
      borderBottom: 'none',
    },
  },
}));

const Channel = ({ selected, onClick, channel, onClickJoin, multiple, isLast }) => {
  const classes = useStyles();
  return (
    <ListItem
      className={classNames({
        [classes.root]: true,
        [classes.disabled]: !channel.isMember,
        [classes.last]: isLast,
      })}
      disableGutters
    >
      <ListItemButton
        role={undefined}
        onClick={channel.isMember ? onClick : () => {}}
        disabled={!channel.isActive}
        selected={selected}
        disableGutters
        sx={{ p: 0 }}
      >
        {multiple ? (
          <ListItemIcon
            sx={{
              ml: 3,
              minWidth: 0,
            }}
          >
            <Checkbox edge="start" checked={selected} tabIndex={-1} disableRipple />
          </ListItemIcon>
        ) : null}
        <div className={classes.avatarContainer}>
          <EmployeeAvatar
            large
            employee={{
              avatar: channel.avatar,
              name: channel.name,
            }}
          />
        </div>
        <ListItemText
          component="div"
          className={classes.itemText}
          primary={
            <Label
              messagesUnreadCount={channel.messagesUnreadCount}
              mandatoriesUnreadCount={channel.mandatoriesUnreadCount}
              createdAt={channel.lastMessage?.createdAt}
              pinned={!!channel.pinned}
            >
              {channel.name} {channel.description ? ` - ${channel.description}` : ''}
            </Label>
          }
          secondary={<LastMessage lastMessage={channel.lastMessage} channel={channel} />}
        />
        {onClickJoin && !channel.isMember && (
          <Button
            onClick={onClickJoin}
            variant="contained"
            color="primary"
            className={classes.joinButton}
          >
            {I18n.t('ChannelsPanel.Join channel')}
          </Button>
        )}
      </ListItemButton>
    </ListItem>
  );
};
Channel.propTypes = {
  selected: bool,
  onClick: func.isRequired,
  channel: channelShape.isRequired,
  onClickJoin: func,
  multiple: bool,
  isLast: bool,
};
Channel.defaultProps = {
  selected: false,
  onClickJoin: null,
  multiple: false,
  isLast: false,
};

const areEqual = (prev, next) => {
  return (
    isEqual(prev.channel.lastMessage, next.channel.lastMessage) &&
    prev.channel.name === next.channel.name &&
    prev.channel._id === next.channel._id &&
    prev.channel.avatar === next.channel.avatar &&
    prev.channel.isActive === next.channel.isActive &&
    prev.channel.messagesUnreadCount === next.channel.messagesUnreadCount &&
    prev.channel.pinned === next.channel.pinned &&
    prev.selected === next.selected &&
    prev.onClick === next.onClick
  );
};
export default memo(Channel, areEqual);
