/* eslint-disable react/jsx-one-expression-per-line */
import { FormControl, TextField, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { Form } from 'formik';
import { bool, func, object, string } from 'prop-types';
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { ErrorBoundary } from 'react-error-boundary';
import { I18n } from 'react-redux-i18n';
import employeeShape from 'shapes/employee';
import AsyncResource from 'utils/AsyncResource';
import useOpenClose from 'utils/useOpenClose';
import AdditionalInfoControls from './AdditionalInfoControls';
import ChannelsControls from './ChannelsControls';
import connector from './connector';
import EmployeeActions from './EmployeeActions';
import formik from './formik';
import JobDetailsControls from './JobDetailsControls';
import PersonalDetailsControls from './PersonalDetailsControls';

const WorkingHoursControls = React.lazy(() => import('./WorkingHoursControls'));

const styles = theme => ({
  root: {
    padding: 20,
    paddingLeft: 40,
    flexGrow: 1,
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    margin: '30px 0 10px 0',
    backgroundColor: theme.palette.secondary.extraLight,
    height: 1,
    border: 0,
  },
  container: {
    flex: '1 1 auto',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
  },
});

const EmployeeLoader = () => (
  <ContentLoader style={{ flex: '0 0 auto' }} width={670} viewBox="0 0 500 840">
    <rect x="0" y="25" rx="3" ry="3" width="140" height="11" />
    <rect x="0" y="52" rx="3" ry="3" width="44" height="11" />
    <rect x="0" y="118" rx="3" ry="3" width="44" height="11" />
    <rect x="0" y="184" rx="3" ry="3" width="44" height="11" />
    <rect x="0" y="250" rx="3" ry="3" width="44" height="11" />
    <rect x="0" y="316" rx="3" ry="3" width="44" height="11" />
    <rect x="0" y="382" rx="3" ry="3" width="44" height="11" />
    <rect x="0" y="419" rx="3" ry="3" width="44" height="11" />
    <rect x="0" y="471" rx="3" ry="3" width="75" height="11" />
    <rect x="103" y="471" rx="3" ry="3" width="30" height="11" />
    <rect x="114" y="43" rx="6" ry="6" width="191" height="32" />
    <rect x="114" y="81" rx="3" ry="3" width="69" height="11" />
    <rect x="114" y="106" rx="6" ry="6" width="191" height="32" />
    <rect x="114" y="144" rx="3" ry="3" width="69" height="11" />
    <rect x="114" y="175" rx="6" ry="6" width="191" height="32" />
    <rect x="114" y="213" rx="3" ry="3" width="69" height="11" />
    <rect x="114" y="238" rx="6" ry="6" width="191" height="32" />
    <rect x="114" y="276" rx="3" ry="3" width="69" height="11" />
    <rect x="114" y="307" rx="6" ry="6" width="191" height="32" />
    <rect x="114" y="345" rx="3" ry="3" width="69" height="11" />
    <rect x="114" y="380" rx="3" ry="3" width="69" height="11" />
    <rect x="114" y="420" rx="3" ry="3" width="69" height="11" />
    <rect x="0" y="534" rx="3" ry="3" width="105" height="11" />
    <rect x="0" y="562" rx="3" ry="3" width="46" height="11" />
    <rect x="103" y="550" rx="6" ry="6" width="395" height="31" />
    <rect x="0" y="636" rx="3" ry="3" width="120" height="11" />
    <rect x="0" y="672" rx="3" ry="3" width="86" height="11" />
    <rect x="103" y="669" rx="6" ry="6" width="28" height="16" />
    <rect x="0" y="719" rx="3" ry="3" width="38" height="11" />
    <rect x="103" y="709" rx="6" ry="6" width="300" height="32" />
    <rect x="417" y="709" rx="6" ry="6" width="82" height="32" />
    <rect x="103" y="757" rx="6" ry="6" width="395" height="80" />
  </ContentLoader>
);
const ErrorLoadingEmployee = e => (
  <Typography style={{ flexGrow: 1 }} color="error">
    {I18n.t('EmployeeDrawer.ErrorLoadingEmployee')}
    {JSON.stringify(e)}
  </Typography>
);

const EmployeeForm = ({
  actions: {
    employees: { load },
    companies: { addTag },
    employeeDrawer: { close },
    auth: { verify },
  },
  isEdit,
  employee,
  employeeId,
  classes,
  isCompanyAdmin,
  authCompany,
  handleReset,
  touched,
  initialValues,
  submitForm,
  values,
  setFieldValue,
  setErrors,
}) => {
  const { loaded } = employee || {};

  const [loading, setLoading] = useState(false);
  const [loadTimeout, setLoadTimeout] = useState(null);
  const createLoadTimeout = useCallback((resolve, reject) => {
    setLoadTimeout(setTimeout(() => reject(new Error('Timeout')), 4000));
  }, []);
  const clearLoadTimeout = useCallback(() => {
    if (loadTimeout) {
      clearTimeout(loadTimeout);
      setLoadTimeout(null);
    }
  }, [loadTimeout]);
  const [employeeResource, setEmployeeResource] = useState(
    new AsyncResource(new Promise(() => {})),
  );

  useEffect(
    () =>
      setEmployeeResource(
        new AsyncResource(
          new Promise((resolve, reject) => {
            if (!isEdit || loaded) {
              resolve(employee);
            } else {
              createLoadTimeout(resolve, reject);
            }
          }),
        ),
      ),
    [createLoadTimeout, employee, isEdit, loaded],
  );

  useEffect(() => {
    if (isEdit && !loaded && !loading) {
      setLoading(true);
      employeeResource.setPromise(
        load(employeeId).then(res => {
          clearLoadTimeout();
          return res;
        }),
      );
    }
    return clearLoadTimeout;
  }, [load, isEdit, employeeId, loaded, employeeResource, clearLoadTimeout, loading]);

  const someTouched = Object.values(touched).some(v => v);
  useEffect(() => {
    if (!someTouched) {
      handleReset({ values: initialValues });
    }
  }, [employee, initialValues, someTouched, handleReset]);

  const [isOpenConfirm, openConfirm, closeConfirm] = useOpenClose();
  const [loginType, setLoginType] = useState('sms');
  const handleSubmit = async e => {
    e.preventDefault();
    if (values.newPassword) {
      try {
        const {
          value: { data, status },
        } = await verify();
        if (status) {
          setLoginType(data.user.loginType);
          openConfirm();
        }
      } catch (error) {
        setErrors({ server: error.message || error.data });
      }
    } else {
      submitForm();
    }
  };

  return (
    <Form className={classes.root} onSubmit={handleSubmit}>
      <Typography variant="h5" gutterBottom>
        {isEdit
          ? I18n.t('EmployeeDrawer.Title.Edit')
          : `${I18n.t('EmployeeDrawer.Title.Add')} ${I18n.t('EmployeeDrawer.Title.employee')}`}
      </Typography>
      <div className={classes.container}>
        <PersonalDetailsControls country={authCompany.country?.toLowerCase()} />
        <hr className={classes.divider} />
        <JobDetailsControls divisions={authCompany.divisions} />
        <hr className={classes.divider} />
        <ErrorBoundary FallbackComponent={ErrorLoadingEmployee}>
          <Suspense fallback={<EmployeeLoader />}>
            <WorkingHoursControls employeeResource={employeeResource} />
            <hr className={classes.divider} />
            <AdditionalInfoControls createTag={addTag} tags={authCompany?.tags || []} />
            <hr className={classes.divider} />
            <ChannelsControls isCompanyAdmin={isCompanyAdmin} employee={employee} />
          </Suspense>
        </ErrorBoundary>
      </div>
      <ConfirmDialog
        message={I18n.t(
          `EmployeeDrawer.${
            loginType === 'password' ? 'Enter current password' : 'Enter SMS code'
          }`,
        )}
        buttons={[
          {
            text: I18n.t('ConfirmDialog.Cancel'),
            onClick: () => {
              closeConfirm();
            },
          },
          {
            text: I18n.t('ConfirmDialog.Confirm'),
            onClick: () => {
              closeConfirm();
              submitForm();
            },
            variant: 'contained',
            color: 'primary',
          },
        ]}
        onClose={closeConfirm}
        isOpen={isOpenConfirm}
      >
        <FormControl style={{ padding: 24 }} fullWidth>
          <TextField
            value={values.currentPassword}
            onChange={e => {
              setFieldValue('currentPassword', e.target.value);
            }}
            type={loginType === 'password' ? 'password' : 'text'}
            autoFocus
            fullWidth
          />
        </FormControl>
      </ConfirmDialog>
      <EmployeeActions isEdit={isEdit} onReset={close} />
    </Form>
  );
};

EmployeeForm.propTypes = {
  classes: object.isRequired,
  actions: object.isRequired,
  employee: employeeShape,
  employeeId: string,
  isEdit: bool.isRequired,
  isCompanyAdmin: bool.isRequired,
  authCompany: object.isRequired,
  touched: object.isRequired,
  initialValues: object.isRequired,
  handleReset: func.isRequired,
  values: object.isRequired,
  submitForm: func.isRequired,
  setFieldValue: func.isRequired,
  setErrors: func.isRequired,
};

EmployeeForm.defaultProps = {
  employee: null,
  employeeId: null,
};
export default withStyles(styles)(connector(formik(EmployeeForm)));
