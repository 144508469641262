import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { HrBotContext } from 'components/@home/drawers/HrBotDrawer/hrBotContext';
import Pagination from 'components/controls/Pagination';
import capitalize from 'lodash/capitalize';
import get from 'lodash/get';
import FindInPageIcon from 'mdi-react/FindInPageIcon';
import { array } from 'prop-types';
import React, { memo, useCallback, useContext, useState } from 'react';
import useDimensions from 'react-cool-dimensions';
import { I18n } from 'react-redux-i18n';
import { useGetNamesQuery } from 'store/app/entities/EmployeesApiSlice';
import Search from './Search';
import searchFiles from './searchFiles';
import TitleCell from './TitleCell';

const PER_PAGE = 20;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    maxHeight: '100%',
    overflow: 'hidden',
  },

  tableWrapper: {
    flexGrow: 1,
    maxHeight: '100%',
    overflow: 'hidden',
  },

  table: {
    maxHeight: '100%',
    overflow: 'hidden',
  },

  tableHead: {
    width: '100%',
    borderTop: `1px solid ${theme.palette.divider}`,
  },

  tableRow: {
    height: 40,
  },

  tableBody: {},

  toolbar: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },

  summary: {
    display: 'flex',
  },

  error: {
    color: theme.palette.danger,
  },

  errors: {
    marginLeft: theme.spacing(1),
  },
  iconButton: {
    padding: 4,
  },
}));

const DroppedFiles = ({ files }) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRows] = useState(PER_PAGE);
  const [searchValue, setSearchValue] = useState('');
  const { navigateTo, setCurrentFile, format } = useContext(HrBotContext);
  const { observe } = useDimensions({
    onResize: ({ height }) => {
      setPage(0);
      setRows(Math.floor((height - 50) / 40));
    },
  });

  const [order, setOrder] = useState({ by: 'employee.name', isReverse: false });
  const error = files.some(f => f.error);
  const hasDni = files.some(f => f.dni);

  const handleSearch = event => {
    setSearchValue(event.currentTarget.value);
    setPage(0);
  };

  const changePage = useCallback(({ selected }) => setPage(selected), []);

  const newFiles = files.map((f, idx) => ({
    ...f,
    [format.sendBy]: f[format.sendBy] && f[format.sendBy].padStart(5, '0'),
    idx,
  }));

  const filtered = searchFiles(searchValue, newFiles).filter(value => !error || value.error);
  const sortFunction = (first, second) => {
    const f = get(first, order.by);
    const s = get(second, order.by);
    let ret = 0;
    if (typeof f === typeof s && typeof f === 'string') {
      ret = f.localeCompare(s);
    } else if (typeof f === typeof s && typeof f === 'undefined') {
      ret = 0;
    } else if (typeof f === 'undefined') {
      ret = -1;
    } else if (typeof s === 'undefined') {
      ret = 1;
    }
    return order.isReverse ? -ret : ret;
  };

  const sorted = filtered.sort(sortFunction);

  const paginated = sorted.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const fatalError = files.some(f => f.error);
  const numSend = files.filter(f => !f.error && f.employee && f.employee[format.sendBy]).length;
  const numErrors = files.filter(f => f.error).length;
  const numNotFound = files.filter(
    f => !f.error && (!f.employee || !f.employee[format.sendBy]),
  ).length;

  return (
    <div className={classes.root}>
      <div className={classes.toolbar}>
        <div className={classes.summary}>
          {!fatalError && (
            <Typography display="inline">
              {I18n.t('HrBot.recipients', { count: numSend })}
            </Typography>
          )}
          {!fatalError && numNotFound > 0 && (
            <Typography color="error" display="inline" className={classes.errors}>
              {I18n.t('HrBot.notFound', { count: numNotFound })}
            </Typography>
          )}
          {numErrors > 0 && (
            <Typography className={classes.errors} color="error" display="inline">
              {I18n.t('HrBot.errors', { count: numErrors })}
            </Typography>
          )}
        </div>
        <Search onChange={handleSearch} value={searchValue} />
      </div>
      <div className={classes.tableWrapper} ref={observe}>
        <Table className={classes.table} aria-labelledby="employeesTable">
          <TableHead className={classes.tableHead}>
            <TableRow classes={{ head: classes.tableRow }}>
              <TitleCell
                name="employee.name"
                label={I18n.t('HrBot.Name')}
                order={order}
                setOrder={setOrder}
                padding="none"
              />
              <TitleCell
                name={format.sendBy}
                label={I18n.t(`HrBot.${capitalize(format.sendBy)}`)}
                order={order}
                setOrder={setOrder}
              />
              <TitleCell
                name="file.name"
                label={I18n.t('HrBot.Original file name')}
                order={order}
                setOrder={setOrder}
              />
              {!error && (
                <TitleCell
                  name="newFileName"
                  label={I18n.t('HrBot.New file name')}
                  order={order}
                  setOrder={setOrder}
                />
              )}
              {hasDni && (
                <TitleCell
                  name="dni"
                  label={I18n.t('HrBot.Password')}
                  order={order}
                  setOrder={setOrder}
                />
              )}
              {error && <TableCell>{I18n.t('HrBot.Errors')}</TableCell>}
              <TableCell align="right">{I18n.t('HrBot.Find elements')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {paginated.map(value => {
              const notFound = (
                <span className={classes.error}>
                  {I18n.t('HrBot.User not registered at Ommnio')}
                </span>
              );
              const openConfig = () => {
                setCurrentFile(value);
                setTimeout(() => navigateTo('config'), 200);
              };
              let val = value[format.sendBy];
              if (val && format.items.find(i => i.name === 'division')) {
                val = (
                  <>
                    {val}
                    <br />
                    {` (${value.division})`}
                  </>
                );
              }
              return (
                <TableRow className={classes.tableRow} key={value.idx}>
                  <TableCell padding="none">
                    {fatalError ? I18n.t('HrBot.not found') : value.employee.name || notFound}
                  </TableCell>
                  <TableCell>{val || I18n.t('HrBot.not found')}</TableCell>
                  <TableCell>
                    {format.type === 'xml'
                      ? value.filename || (
                          <span className={classes.error}>{I18n.t('HrBot.not found')}</span>
                        )
                      : value.file.name}
                    {format.type === 'single-pdf' && ` p.${value.pageNum}`}
                  </TableCell>
                  {!error && <TableCell>{value.newFileName}</TableCell>}
                  {hasDni && <TableCell>{value.dni}</TableCell>}
                  {error && (
                    <TableCell>
                      <span className={classes.error}>
                        {Object.entries(value)
                          .filter(([k, v]) => !v && k !== 'idx' && k !== 'newFileName')
                          .map(([k]) => I18n.t(`HrBot.fields.${k}`))
                          .join(', ')}
                      </span>
                    </TableCell>
                  )}
                  <TableCell align="right">
                    <IconButton onClick={openConfig} className={classes.iconButton} size="large">
                      <FindInPageIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <div>
        <Pagination
          perPage={rowsPerPage}
          total={filtered.length}
          page={page}
          onChange={changePage}
        />
      </div>
    </div>
  );
};

DroppedFiles.propTypes = {
  files: array.isRequired,
};

export default memo(DroppedFiles);
