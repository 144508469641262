import { rectangleSelect } from 'components/@home/drawers/HrBotDrawer/selection-utils';
import { I18n } from 'react-redux-i18n';
import nth from 'utils/nth';
import getPdfjs from 'utils/pdfjs';
import { DOMParser } from 'xmldom';
import xpath from 'xpath2';

export const getXPathValue = async (xml, xp) => {
  const doc = new DOMParser().parseFromString(xml);
  return xpath.evaluate(`string(${xp})`, doc).stringValue;
};

export const getPartsFromXML = async (file, xml, format, partDone = () => null) => {
  let values = {};
  const doc = new DOMParser().parseFromString(xml);
  values = format.items.reduce((ret, { name, selector, xpath: xp }) => {
    if (selector === 'user') {
      return ret;
    }
    return { ...ret, [name]: xpath.evaluate(`string(${xp})`, doc).stringValue };
  }, {});
  partDone(values, 1);
  return values;
};

const getWordsFromPage = async (pdf, pageNum) => {
  const page = await pdf.getPage(pageNum);
  const viewport = page.getViewport({ scale: 1 });
  const textContent = await page.getTextContent();
  let textLayerDiv = document.getElementById('hrBotTextLayerDiv');
  if (textLayerDiv) {
    document.body.removeChild(textLayerDiv);
    textLayerDiv.innerHtml = '';
  }
  textLayerDiv = document.createElement('div');
  textLayerDiv.setAttribute('id', 'hrBotTextLayerDiv');
  textLayerDiv.className = 'textLayerDiv';
  textLayerDiv.style.display = 'none';
  textLayerDiv.style.width = viewport.width;
  textLayerDiv.style.height = viewport.height;
  textLayerDiv.style.setProperty('--scale-factor', 1);
  document.body.appendChild(textLayerDiv);
  const pdfjs = await getPdfjs();
  await pdfjs.renderTextLayer({
    textContentSource: textContent,
    container: textLayerDiv,
    viewport,
    enhanceTextSelection: true,
  }).promise;
  textContent.items.forEach((t, index) => {
    // eslint-disable-next-line no-underscore-dangle
    const font = Object.values(page.commonObjs._objs || {}).find(
      o => o.data?.loadedName === t.fontName,
    );
    const span = textLayerDiv.children[index];
    if (font?.data?.ascent) {
      if (span) {
        span.style.transform += ` translateY(${(font?.data?.ascent - 1) * 100}%)`;
      }
    }
  });

  const words = textContent.items.reduce((ret, item) => {
    const x = item.transform[4] - viewport.viewBox[0];
    const y = viewport.viewBox[3] - (item.transform[5] + item.transform[3]);
    return {
      ...ret,
      [`${x},${y},${item.width},${item.height}`]: item.str,
    };
  }, []);
  return { words, textLayerDiv };
};

const getPartsFromPDF = async (file, pdf, format, partDone = () => null) => {
  const split = format.type === 'single-pdf';
  const ret = [];
  for (let pageNum = 1; pageNum <= pdf.numPages; pageNum += 1) {
    // eslint-disable-next-line no-await-in-loop
    const { words, textLayerDiv } = await getWordsFromPage(pdf, pageNum);
    const parts = format.items.reduce((acc, item) => {
      try {
        let arr;
        if (item.selector === 'user') {
          return acc;
        }
        if (item.selector === 'box') {
          const b = item.coords;
          const selected = rectangleSelect(textLayerDiv.querySelectorAll('span'), b);
          arr = selected && document.getSelection().toString().trim();
        } else if (item.selector === 'coords' && item.coords.match(/>/)) {
          const [{ n: x, gt: gtx }, { n: y, gt: gty }] = item.coords
            .split(',')
            .map(n => ({ n: +n.replace('>', ''), gt: n.startsWith('>') }));
          [, arr] = Object.entries(words).find(([key]) => {
            const [wx, wy] = key.split(',').map(n => +n);
            return (gtx ? wx > x : wx === x) && (gty ? wy > y : y === wy);
          });
        } else if (item.selector === 'fileName') {
          const splitRegexp = new RegExp(`[${item.separators}]`);
          arr = file.split(splitRegexp);
        } else {
          [, arr] = Object.entries(words).find(([key]) => key.startsWith(item.coords)) || [];
        }
        arr = typeof arr === 'string' ? arr.trim().split(/[^\w-/]+/) : arr;
        const w = item.selector === 'box' ? arr[0] : nth(arr, item.position);
        return { ...acc, [item.name]: w.replace(/[^ \w-/]/g, '').trim() };
      } catch (e) {
        return { ...acc, [item.name]: '' };
      }
    }, {});
    ret.push(parts);
    // eslint-disable-next-line no-await-in-loop
    await partDone(parts, pageNum);
    if (!split) {
      break;
    }
  }
  return ret;
};

export const getFileName = (partsIn, format) => {
  const parts = { ...partsIn };
  const partMap = format.items.reduce((acc, item) => ({ ...acc, [item.name]: item }), {});
  format.items
    .filter(i => i.selector === 'user')
    .forEach(item => {
      if (!parts[item.name]) {
        const value = I18n.t(`HrBot.fields.${item.name}`);
        parts[item.name] = `{${value}}`;
      }
    });
  let { year, month, day } = parts;
  let sendBy = parts[format.sendBy];
  const { dni } = parts;
  if (year?.length < 4) {
    year = `20${year}`;
  }
  sendBy = sendBy ? sendBy.padStart(5, '0') : dni;
  year = partMap.year && year ? `${year}_` : '';
  month = partMap.month && month ? `${month}_` : '';
  day = partMap.day && day ? `${day}_` : '';
  let pref = format.prefix || '';
  if (pref && !pref.endsWith('_')) {
    pref += '_';
  }
  pref += `${year}${month}${day}`;
  return sendBy ? `${pref}${sendBy}.pdf` : '';
};

const getPartFunctions = {
  'multi-pdf': getPartsFromPDF,
  'single-pdf': getPartsFromPDF,
  xml: getPartsFromXML,
};

export const getParts = async (file, fileContents, format, partDone = () => null) => {
  return getPartFunctions[format.type](file, fileContents, format, partDone);
};
