import { QrCode, Send } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InvitationToPrint from 'components/@home/drawers/ProfileDrawer/Profile/EmployeeActions/PrintInvitation/InvitationToPrint';
import Alert from 'components/alerts/Alert';
import ConfirmDialog from 'components/common/ConfirmDialog';
import Loading from 'components/common/Loading';
import ms from 'ms';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { useReactToPrint } from 'react-to-print';
import {
  useInviteMutation,
  useInvitePreviewQuery,
  useListQuery,
} from 'store/app/entities/EmployeesApiSlice';
import authCompany from 'store/selectors/authCompany';

const useStyles = makeStyles(theme => ({
  root: {
    marginRight: 10,
    color: theme.palette.primary.ultraDark,
  },
  plus: {
    fontSize: 18,
    marginRight: 5,
    color: theme.palette.primary.main,
  },
  form: {
    display: 'none',
  },
  close: {
    marginRight: -12,
  },
}));

const autoHideDuration = ms('10 seconds');
const alertInitialState = { isOpen: false };

const MassiveInviteButton = ({ search, filters }) => {
  const componentRef = useRef();
  const company = useSelector(authCompany);
  const [printQRs, setPrintQRs] = useState(false);
  const { data, isFetching } = useInvitePreviewQuery({ companyId: company._id, search, filters });
  const { data: { data: employees } = {}, isSuccess } = useListQuery(
    {
      companyId: company._id,
      search,
      filters: { ...filters, status: 'Pending' },
      limit: 100000,
    },
    { skip: !printQRs },
  );
  const [massiveInvite] = useInviteMutation();
  const total = data?.sms + data?.email + data?.qr;
  const sendTotal = data?.sms + data?.email;
  const classes = useStyles();
  const [alert, setAlert] = useState(alertInitialState);
  const [isOpen, setOpen] = useState(false);
  const print = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      setPrintQRs(false);
    },
    onAfterPrint: () => {
      setOpen(false);
    },
    onPrintError: () => {
      setOpen(false);
    },
  });
  const handlePrint = useCallback(() => {
    setPrintQRs(true);
  }, []);
  useEffect(() => {
    if (printQRs && isSuccess) {
      setTimeout(print, 100);
    }
  }, [isSuccess, print, printQRs]);
  const onClose = useCallback(() => {
    setAlert(a => ({ ...a, ...alertInitialState }));
  }, []);
  const confirmDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const onCloseConfirmDialog = useCallback(() => {
    setOpen(false);
  }, []);
  const onConfirmInvite = useCallback(async () => {
    const resp = await massiveInvite({
      companyId: company._id,
      search,
      filters,
    });
    onCloseConfirmDialog();
    if (resp.error) {
      setAlert({
        isOpen: true,
        variant: 'error',
        label: I18n.t('MassiveInvite.Error'),
        message:
          resp.error.data?.data?.error?.message || I18n.t('AttachFile.Errors.Unexpected error'),
        caption: '',
      });
      return;
    }
    setAlert({
      isOpen: true,
      variant: 'success',
      message: I18n.t('MassiveInvite.Success'),
      caption: I18n.t('MassiveInvite.Sent', { count: resp.data?.sms, email: resp.data?.email }),
    });
  }, [company._id, filters, massiveInvite, onCloseConfirmDialog, search]);
  return (
    <>
      <Button
        onClick={confirmDialog}
        variant="outlined"
        color="secondary"
        className={classes.root}
        disabled={total < 1}
      >
        {isFetching ? (
          <Loading size={14} />
        ) : (
          I18n.t('PeoplesScene.MassiveInvite', { count: total })
        )}
      </Button>
      <ConfirmDialog
        message={I18n.t('MassiveInvite.Alert_Title', { count: total })}
        buttons={[
          {
            text: I18n.t('MassiveInvite.Alert_Cancel_Btn'),
            color: 'primary',
            sx: { marginRight: 'auto' },
          },
          {
            endIcon: <QrCode />,
            text: I18n.t('MassiveInvite.Alert_Print_QRs_Btn', { count: total }),
            variant: 'contained',
            color: 'primary',
            onClick: handlePrint,
          },
          {
            endIcon: <Send />,
            text: I18n.t('MassiveInvite.Alert_Confirm_Btn', { count: sendTotal }),
            variant: 'contained',
            color: 'primary',
            onClick: onConfirmInvite,
          },
        ]}
        isOpen={isOpen}
        onClose={onCloseConfirmDialog}
      >
        <Typography>
          {I18n.t('MassiveInvite.Alert_Message', {
            count: data?.sms,
            email: data?.email,
            qr: total,
          })}
        </Typography>
        <div style={{ display: 'none' }}>
          <div ref={componentRef}>
            {employees?.map(employee => (
              <InvitationToPrint key={employee._id} employee={employee} />
            ))}
          </div>
        </div>
      </ConfirmDialog>
      <Alert
        isOpen={alert.isOpen}
        variant={alert.variant}
        onClose={onClose}
        label={alert.label || I18n.t('PeoplesScene.MassiveInvite', { count: sendTotal })}
        caption={alert.caption}
        autoHideDuration={autoHideDuration}
      >
        {alert.message}
      </Alert>
    </>
  );
};

MassiveInviteButton.propTypes = {
  search: PropTypes.string,
  filters: PropTypes.object,
};

MassiveInviteButton.defaultProps = {
  search: '',
  filters: {},
};

export default MassiveInviteButton;
