import React, { memo } from 'react';
import { I18n } from 'react-redux-i18n';
import { func, string, bool, shape } from 'prop-types';
import Cell from './Cell';

const cells = [
  { key: 'name', disablePadding: false, label: I18n.t('ChannelDrawer.EmployeesTable.Name') },
  {
    key: 'department',
    disablePadding: false,
    label: I18n.t('ChannelDrawer.EmployeesTable.Department'),
  },
  {
    key: 'jobTitle',
    disablePadding: false,
    label: I18n.t('ChannelDrawer.EmployeesTable.Job Position'),
  },
  {
    key: 'location',
    disablePadding: false,
    label: I18n.t('ChannelDrawer.EmployeesTable.Location'),
  },
  {
    key: 'role',
    disablePadding: false,
    label: I18n.t('ChannelDrawer.EmployeesTable.Role'),
  },
  {
    key: 'tags',
    disablePadding: false,
    label: I18n.t('ChannelDrawer.EmployeesTable.Tags'),
  },
];

const TitleCells = ({ onSort, order, isEdit }) =>
  cells.map(cell => (
    <Cell
      key={cell.key}
      name={cell.key}
      order={order}
      onSort={onSort}
      label={cell.label}
      sortable={isEdit || cell.key !== 'role'}
    />
  ));

TitleCells.propTypes = {
  isEdit: bool.isRequired,
  onSort: func.isRequired,
  order: shape({
    by: string,
    isReverse: bool,
  }).isRequired,
};

export default memo(TitleCells);
