import { Table, TableBody } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Loading from 'components/common/Loading';
import Pagination from 'components/controls/Pagination';
import { connect } from 'formik';
import { arrayOf, bool, func, object, string } from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import Company from 'services/api/Company';
import { useListVisibleQuery } from 'store/app/entities/EmployeesApiSlice';
import authEmployee from 'store/selectors/authEmployee';
import EmployeeRow from './EmployeeRow';
import TableHead from './TableHead';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: theme.spacing(3),
    maxWidth: 'calc(100%- 100px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  notFound: {
    fontSize: 13,
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'center',
    paddingTop: '2em',
  },
}));

const limit = 10;

const union = (value, added, by, base) => {
  return [
    ...value,
    ...added
      .filter(a => !value.some(v => (by ? v[by] : v) === a))
      .map(a => (by ? { ...base, [by]: a } : a)),
  ];
};

const EmployeesTable = ({ name, onChange, value, formik: { values }, isEdit }) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState({ by: 'name', isReverse: false });
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const employee = useSelector(authEmployee);
  const {
    data: { employees: employeesWithMe, metadata } = {},
    isLoading,
    isFetching,
    error,
  } = useListVisibleQuery({
    search,
    skip: page * limit,
    limit,
    order: (order.isReverse ? '-' : '') + order.by,
    group: values._id,
  });
  const employees = useMemo(
    () => employeesWithMe?.filter(e => e._id !== employee?._id),
    [employee?._id, employeesWithMe],
  );

  const selectAll = async enabled => {
    const data = await Company.getAdminEmployeesIds(search);
    if (enabled) {
      onChange(name, union(values[name], data, '_id', { groupRole: 'member' }));
      onChange(`${name}Added`, union(values[`${name}Added`], data, '_id', { groupRole: 'member' }));
      onChange(
        `${name}Removed`,
        values[`${name}Removed`].filter(_id => value.some(v => v === _id)),
      );
    } else {
      onChange(
        name,
        values[name].filter(v => !data.includes(v._id)),
      );
      onChange(
        `${name}Added`,
        values[`${name}Added`].filter(v => !data.includes(v._id)),
      );
      onChange(`${name}Removed`, union(values[`${name}Removed`], data));
    }
  };

  const setRole = (employee, groupRole) => {
    const newValue = value.map(m => (m._id === employee._id ? { ...m, groupRole } : m));
    onChange(name, newValue);
  };

  const toggleSelect = employee => {
    if (value.find(m => m._id === employee._id)) {
      onChange(
        name,
        value.filter(m => m._id !== employee._id),
      );
      onChange(
        `${name}Added`,
        values[`${name}Added`].filter(e => e._id !== employee._id),
      );
      onChange(`${name}Removed`, [...values[`${name}Removed`], employee]);
    } else {
      onChange(name, [...value, { groupRole: 'member', ...employee }]);
      onChange(`${name}Added`, [...values[`${name}Added`], { groupRole: 'member', ...employee }]);
      onChange(
        `${name}Removed`,
        values[`${name}Removed`].filter(e => e._id !== employee._id),
      );
    }
  };

  const sort = by => {
    setOrder(currentOrder => ({ by, isReverse: !currentOrder.isReverse }));
  };

  const onSearch = newSearch => {
    setSearch(newSearch);
    setPage(0);
  };

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  const selectedCount = value.length;

  if (isLoading) {
    return <Loading />;
  }

  const employeesCount = metadata.total;

  return (
    <div className={classes.root}>
      <Toolbar error={error} selected={selectedCount} onSearch={onSearch} isFetching={isFetching} />
      <Box sx={{ flexGrow: 1, mb: 2 }}>
        <Table className={classes.table} aria-labelledby="employeesTable">
          <TableHead
            isEdit={isEdit}
            search={search}
            order={order}
            selected={selectedCount}
            rows={employeesCount}
            onSort={sort}
            onSelectAll={selectAll}
          />
          <TableBody className={classes.tableBody}>
            {employees.map(e => (
              <EmployeeRow
                key={e._id}
                employee={e}
                member={value.find(m => m._id === e._id)}
                onSelect={toggleSelect}
                onSetRole={setRole}
                searchString={search}
                disabled={isFetching}
              />
            ))}
          </TableBody>
        </Table>
      </Box>
      <Pagination total={employeesCount} page={page} perPage={limit} onChange={changePage} />
      {employeesCount === 0 && search ? (
        <div className={classes.notFound}>{I18n.t('ChannelDrawer.EmployeesTable.NotFound')}</div>
      ) : null}
    </div>
  );
};

EmployeesTable.propTypes = {
  isEdit: bool.isRequired,
  name: string.isRequired,
  value: arrayOf(string).isRequired,
  onChange: func.isRequired,
  formik: object.isRequired,
};

export default connect(EmployeesTable);
