/* eslint-disable operator-linebreak */
import { Chip, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Loading from 'components/common/Loading';
import FormikAutosuggest from 'components/formik/Autosuggest';
import Checkbox from 'components/formik/Checkbox';
import TextField from 'components/formik/TextField';
import { FastField, Field, Form } from 'formik';
import { arrayOf, bool, number, object } from 'prop-types';
import React, { useEffect } from 'react';
import { I18n } from 'react-redux-i18n';
import employeeShape from 'shapes/employee';
import teamShape from 'shapes/team';
import { useLazyListTeamsQuery } from 'store/app/entities/TeamsSlice';
import loadOptionsLazyQuery from 'utils/loadOptionsLazyQuery';
import connector from './connector';
import formik from './formik';
import ActionButtons from './formik/ActionButtons';
import EmployeesTable from './formik/EmployeesTable';
import Headline from './Headline';
import Label from './Label';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20,
  },
  inputs: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    paddingBottom: 30,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& > div': {
      width: '100%',
    },
  },
  type: {
    minWidth: 680,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    justifyContent: 'space-between',
  },

  upload: {
    paddingTop: 20,
    paddingRight: 20,
  },

  leave: {
    display: 'flex',
    alignItems: 'center',
    justifySelf: 'flex-start',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  errorMessage: {
    padding: '0 20px',
    alignSelf: 'center',
  },
});

const TeamForm = ({
  team,
  teamId,
  classes,
  errors,
  isEdit,
  actions,
  loading,
  values,
  isSuperAdmin,
}) => {
  const [triggerListTeamsQuery] = useLazyListTeamsQuery();
  useEffect(() => {
    if (isEdit) {
      actions.teams.find(teamId);
    }
  }, [actions.teams, teamId, isEdit]);

  const handleReset = () => {
    actions.drawers.team.close();
  };

  if (isEdit && !team?.users) {
    return <Loading />;
  }

  return (
    <Form className={classes.root}>
      <Headline isEdit={isEdit} />
      <div className={classes.inputs}>
        <div>
          <Label>{I18n.t('TeamDrawer.NameField.label')}</Label>
          <FastField
            component={TextField}
            autoComplete="off"
            placeholder={I18n.t('TeamDrawer.NameField.placeholder')}
            name="name"
            fullWidth
          />
          <Label>{I18n.t('TeamDrawer.DescriptionField.label')}</Label>
          <FastField
            component={TextField}
            autoComplete="off"
            placeholder={I18n.t('TeamDrawer.DescriptionField.placeholder')}
            name="description"
          />
          <Label>{I18n.t('TeamDrawer.ParentField.label')}</Label>
          <FastField
            component={FormikAutosuggest}
            loadOptions={loadOptionsLazyQuery}
            additional={{ page: 1, exclude: values.teams, trigger: triggerListTeamsQuery }}
            placeholder={I18n.t('TeamDrawer.ParentField.placeholder')}
            name="parent"
            isClearable
          />
          <Label>{I18n.t('TeamDrawer.DefaultRoleField.label')}</Label>
          <FastField
            component={FormikAutosuggest}
            suggestions={['member', 'admin', 'guest'].map(value => ({
              label: I18n.t(`Roles.${value}`),
              value,
            }))}
            placeholder={I18n.t('TeamDrawer.DefaultRoleField.placeholder')}
            name="defaultRole"
          />
          {team && (
            <div className={classes.channels}>
              <Label>{I18n.t('TeamDrawer.Chats')}</Label>
              {team.groups?.map(c => (
                <Chip
                  label={c.name + (c.description ? ` - ${c.description}` : '')}
                  key={c.name}
                  className={classes.tag}
                />
              ))}
              <Typography variant="body2">
                {team.groups?.length ? null : I18n.t('TeamDrawer.No Chats')}
              </Typography>
            </div>
          )}
        </div>
      </div>

      {loading ? (
        <Loading size={56} />
      ) : (
        <Field component={EmployeesTable} defaultRole={values.defaultRole} name="members" />
      )}

      <div className={classes.actions}>
        {(isSuperAdmin || !values.isReadOnly) && (
          <div className={classes.leave}>
            <FastField
              disabled={values.optOutDisabled}
              component={Checkbox}
              label={I18n.t('TeamDrawer.Opt out from this team')}
              name="isOptedOut"
            />
          </div>
        )}
        <div className={classes.errorContainer}>
          {errors.server && (
            <Typography align="right" color="error" className={classes.errorMessage}>
              {errors.server}
            </Typography>
          )}
        </div>
        <ActionButtons onReset={handleReset} isEdit={isEdit} disabled={loading} />
      </div>
    </Form>
  );
};

TeamForm.propTypes = {
  teamId: number.isRequired,
  team: teamShape,
  classes: object.isRequired,
  actions: object.isRequired,
  isEdit: bool.isRequired,
  employees: arrayOf(employeeShape).isRequired,
  errors: object.isRequired,
  loading: bool.isRequired,
  values: object.isRequired,
  isSuperAdmin: bool.isRequired,
};

TeamForm.defaultProps = {
  team: null,
};

export default withStyles(styles)(connector(formik(TeamForm)));
