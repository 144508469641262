/** @flow */
import { Button, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Label from 'components/@home/drawers/ChannelDrawer/Form/Label';
import usePrevious from 'components/common/usePrevious';
import PhotoUpload from 'components/controls/PhotoUpload';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import authCompanyId from 'store/selectors/authCompanyId';
import currentChannel from 'store/selectors/currentChannel';
import Attachments from 'services/api/Attachments';
import keyIs, { ENTER } from 'utils/keyIs';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    p: 2,
    width: '30vw',
    '& .MuiInputBase-multiline': {
      p: 0,
    },
  },
  inputs: {
    display: 'flex',
    flex: '1 0 auto',
    flexWrap: {
      sm: 'wrap',
      md: 'nowrap',
    },
    paddingBottom: 30,
    borderBottom: `1px solid`,
    borderColor: 'secondary.extraLight',
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 2,
    gap: 2,
  },
  thumb: {
    width: '100%',
    height: 120,
  },
};

type Props = {
  close: () => void;
  content?: 'title/subtitle' | 'featured-image' | 'all';
  disabled?: boolean;
  discardText?: string;
  isOpen?: boolean;
  onSubmit?: () => void;
  saveText?: string;
};

const ArticleConfigPopover = ({
  close,
  isOpen,
  content,
  disabled,
  discardText,
  saveText,
  onSubmit,
}: Props) => {
  const showTitleSubtitle = content === 'title/subtitle' || content === 'all';
  const showFeaturedImage = content === 'featured-image' || content === 'all';
  const locale = useSelector(state => state.i18n.locale);
  const [progress, setProgress] = useState(null);
  const isUploading = useMemo(() => progress !== null && progress < 100, [progress]);
  const channel = useSelector(currentChannel);
  const companyId = useSelector(authCompanyId);
  const titleRef = useRef();
  const { register, watch, setValue, getValues, resetField } = useFormContext();
  const preview = watch('preview');
  const title = watch('title');
  const subtitle = watch('subtitle');
  const [initialValues, setInitialValues] = useState({});
  const shouldSave = useRef(false);
  const saveChanges = useCallback(
    e => {
      e.stopPropagation();
      e.preventDefault();
      shouldSave.current = true;
      if (onSubmit) {
        onSubmit();
      } else {
        close();
      }
    },
    [close, onSubmit],
  );
  const wasOpen = usePrevious(isOpen);
  const prevContent = usePrevious(content);
  const wasShowTitleSubtitle = prevContent === 'title/subtitle' || prevContent === 'all';
  useEffect(() => {
    if (isOpen && !wasOpen) {
      setInitialValues({ ...getValues('preview') });
    }
  }, [getValues, isOpen, wasOpen]);
  useEffect(() => {
    if (isOpen && !wasOpen && titleRef.current && showTitleSubtitle) {
      titleRef.current.focus();
      if (!preview?.title) {
        resetField('preview.title', { defaultValue: title });
      }
      if (preview?.subtitle === null) {
        resetField('preview.subtitle', { defaultValue: subtitle?.trim() });
      }
    }
  }, [
    isOpen,
    preview?.subtitle,
    preview?.title,
    resetField,
    showTitleSubtitle,
    subtitle,
    title,
    wasOpen,
  ]);
  useEffect(() => {
    if (!isOpen && wasOpen && wasShowTitleSubtitle) {
      if (!shouldSave.current) {
        Object.entries(initialValues).forEach(([key, value]) => {
          setValue(`preview.${key}`, value);
        });
      }
      shouldSave.current = false;
      if (preview?.title?.trim() === title?.trim()) {
        resetField('preview.title', { defaultValue: null });
      }
      if (preview?.subtitle === null || preview?.subtitle.trim() === subtitle?.trim()) {
        resetField('preview.subtitle', { defaultValue: null });
      }
    }
  }, [
    initialValues,
    isOpen,
    preview?.subtitle,
    preview?.title,
    resetField,
    setValue,
    subtitle,
    title,
    wasOpen,
    wasShowTitleSubtitle,
  ]);
  const handleFocus = useCallback(e => {
    const { target } = e;
    setTimeout(() => {
      target.selectionEnd = 500;
    }, 0);
  }, []);
  const submitOnEnter = useCallback(
    e => {
      if (keyIs(e, ENTER)) {
        e.preventDefault();
        saveChanges(e);
      }
    },
    [saveChanges],
  );
  const onUploadProgress = useCallback(
    progressEvent => {
      setProgress(progressEvent.progress * 100);
    },
    [setProgress],
  );
  const handleImageChange = useCallback(
    async file => {
      setProgress(0);
      const { httpLink } = await Attachments.upload(
        companyId,
        channel._id,
        file,
        locale,
        onUploadProgress,
      );
      setValue('preview.image', httpLink, { shouldDirty: true });
    },
    [channel._id, companyId, locale, onUploadProgress, setValue],
  );
  if (!isOpen) return null;
  return (
    <Box component="form" sx={styles.root} onSubmit={saveChanges}>
      {showFeaturedImage && (
        <>
          <Label>{I18n.t('ArticleConfigDrawer.articleImage')}</Label>
          <PhotoUpload
            {...register('preview.image')}
            value={preview?.image}
            onChange={handleImageChange}
            progress={progress}
            edit={!!preview?.image}
            sx={styles.thumb}
          />
        </>
      )}
      {showTitleSubtitle && (
        <>
          <Label>{I18n.t('ArticleConfigDrawer.title')}</Label>
          <TextField
            {...register('preview.title')}
            defaultValue={title?.trim()}
            placeholder={title}
            inputRef={titleRef}
            onFocus={handleFocus}
            multiline
            fullWidth
            onKeyDown={submitOnEnter}
          />
          <Label>{I18n.t('ArticleConfigDrawer.subtitle')}</Label>
          <TextField
            {...register('preview.subtitle')}
            defaultValue={subtitle?.trim()}
            onFocus={handleFocus}
            multiline
            fullWidth
            onKeyDown={submitOnEnter}
          />
        </>
      )}
      <Typography variant="body1" sx={{ mt: 2 }}>
        {I18n.t('ArticleConfigDrawer.These properties will')}
      </Typography>
      <Box sx={styles.actions}>
        <Button disabled={disabled} variant="outlined" color="secondary" onClick={close}>
          {discardText || I18n.t('ArticleConfigDrawer.Discard')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={disabled || isUploading}
        >
          {saveText || I18n.t('ArticleConfigDrawer.Save')}
        </Button>
      </Box>
    </Box>
  );
};

ArticleConfigPopover.defaultProps = {
  content: 'all',
  disabled: false,
  discardText: null,
  isOpen: false,
  onSubmit: null,
  saveText: null,
};

export default ArticleConfigPopover;
