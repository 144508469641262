/* eslint-disable react/no-array-index-key */
import { Button, IconButton, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import { HrBotContext } from 'components/@home/drawers/HrBotDrawer/hrBotContext';
import ItemCheckBox from 'components/@home/drawers/HrBotDrawer/ItemCheckBox';
import { getXPathValue } from 'components/@home/drawers/HrBotDrawer/pdf-utils';
import { rectangleSelect } from 'components/@home/drawers/HrBotDrawer/selection-utils';
import XmlPreview from 'components/@home/drawers/HrBotDrawer/XmlPreview';
import PdfPreview from 'components/@home/modals/PdfPreview';
import Autosuggest from 'components/controls/Autosuggest';
import CopyField from 'components/controls/CopyField';
import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import startCase from 'lodash/startCase';
import throttle from 'lodash/throttle';
import FormatTextdirectionLToRIcon from 'mdi-react/FormatTextdirectionLToRIcon';
import FormatTextdirectionRToLIcon from 'mdi-react/FormatTextdirectionRToLIcon';
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import documentFormats from 'store/app/entities/documentFormats/action';
import authUser from 'store/selectors/authUser';
import hasDivisionsSelector from 'store/selectors/hasDivisionsSelector';
import numSort from 'utils/numSort';

const useStyles = makeStyles(th => ({
  blanket: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    background: 'black',
    opacity: 0,
    zIndex: 100,
    transition: 'opacity 400ms ease-in-out',
    pointerEvents: ({ selecting }) => (selecting ? 'all' : 'none'),
  },
  selecting: {
    opacity: 0.7,
  },
  content: {
    marginTop: th.spacing(1),
    marginBottom: th.spacing(1),
    flexGrow: 1,
    display: 'flex',
    maxHeight: '100%',
    overflow: 'auto',
    alignItems: 'flex-start',
    flexDirection: 'column',
    cursor: ({ selecting, type }) => {
      if (selecting) {
        return type === 'xml' ? 'not-allowed' : 'crosshair';
      }
      return 'default';
    },
  },
  navigationButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    marginBottom: th.spacing(2),
  },
  controls: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: th.spacing(1),
  },
  submit: {
    margin: th.spacing(3, 0, 2),
    height: 40,
    maxHeight: 40,
  },
  selectWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    marginTop: th.spacing(1),
  },
  select: {
    flex: 1,
  },
  save: {
    marginTop: 10,
    marginRight: 10,
    background: `linear-gradient(left, darken(${th.palette.primary.main},20%), darken(${th.palette.primary.main},20%) 50%, ${th.palette.primary.main} 50%, ${th.palette.primary.main})`,
    backgroundSize: '200% 100%',
    backgroundPosition: '100% 0',
    transition: 'background-position .3s ease-out',
  },
  saved: {
    backgroundPosition: '0 0',
  },
  pdfActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    paddingLeft: 11,
  },
  pdfActionsMiddle: {
    flexGrow: 1,
  },
  switch: {
    marginLeft: 0,
  },
  input: {
    marginTop: 10,
  },
  fileName: {
    border: `solid 1px ${th.palette.secondary.light}`,
    borderRadius: 6,
    padding: 10,
    height: '1.1876em',
    fontSize: '0.8125rem',
    marginTop: 10,
    boxSizing: 'content-box',
  },
  partSelected: {
    backgroundColor: th.palette.primary.extraLight,
  },
  iconButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  items: {
    width: '100%',
    tableLayout: 'fixed',
    '& tr': {
      verticalAlign: 'top',
    },
  },
  pdf: {
    marginTop: th.spacing(1),
    borderRadius: 6,
    overflow: 'auto',
    width: '100%',
    zIndex: ({ selecting }) => (selecting ? 200 : 'auto'),
  },
}));

const getSelectedNodes = () => {
  if (document.getSelection) {
    const sel = document.getSelection();
    if (!sel.isCollapsed) {
      const range = sel.getRangeAt(0).cloneRange();
      if (range.startContainer.nodeType === 3) {
        range.setStartBefore(range.startContainer.parentElement);
      }
      if (range.endContainer.nodeType === 3) {
        range.setEndAfter(range.endContainer.parentElement);
      }
      return Array.from(range.cloneContents().querySelectorAll('span'));
    }
  }
  return [];
};

const getPos = (selectedText, reverse = false) => {
  let join = '';
  const selectedNodesWordsArr = getSelectedNodes().map(n => n.innerText.trim());
  let selectedNodesWords = selectedNodesWordsArr.join(join).split(/[^\w-/]+/);
  let pos = selectedNodesWords.indexOf(selectedText);
  if (pos < 0) {
    join = ' ';
    selectedNodesWords = selectedNodesWordsArr.join(join).split(/[^\w-/]+/);
    pos = selectedNodesWords.indexOf(selectedText);
  }
  if (pos < 0) {
    return { join, pos: null };
  }
  if (reverse) {
    pos -= selectedNodesWords.length;
  }
  return {
    join,
    pos,
  };
};

const HrBotConfig = () => {
  const theme = useTheme();
  const hasDivisions = useSelector(hasDivisionsSelector);
  const user = useSelector(authUser);
  const dispatch = useDispatch();
  const { currentFile, formats, formatId, navigateBack } = useContext(HrBotContext);
  const { pageNum: currentPage } = currentFile;
  const canvas = useRef();
  const format = formats[formatId];
  const [selectedItem, setSelectedItem] = useState(null);
  const [foundItems, setFoundItems] = useState(currentFile || {});
  const [items, setItems] = useState(
    format?.items.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {}) || {},
  );
  const item = items[selectedItem];
  const [handlersStatus, setHandlersStatus] = useState('setting');
  const [scale, setScale] = useState(null);

  const getPageUnits = useCallback(n => +(n * scale).toFixed(3), [scale]);
  const getCanvasUnits = useCallback(n => n / scale, [scale]);

  const [reverse, setReverse] = useState(false);
  const [status, setStatus] = useState(null);

  const [selecting, setSelecting] = useState(false);

  const classes = useStyles({ selecting, type: format.type });

  const setItem = it => {
    const newItem = { ...it };
    delete newItem.selected;
    if (it.error) {
      setFoundItems(fi => {
        const newItems = { ...fi };
        delete newItems[it.name];
        return newItems;
      });
    }
    setItems(its => ({ ...its, [newItem.name]: newItem }));
  };

  useEffect(() => {
    if (canvas.current) {
      const ctx = canvas.current.getContext('2d');
      ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
      if (item?.selector === 'box' && item?.coords?.left) {
        ctx.strokeStyle = theme.palette.tertiary.dark;
        const dpr = window.devicePixelRatio || 1;
        ctx.lineWidth = 1.5 * dpr;
        ctx.strokeRect(
          getCanvasUnits(item.coords.left),
          getCanvasUnits(item.coords.top),
          getCanvasUnits(item.coords.right - item.coords.left),
          getCanvasUnits(item.coords.bottom - item.coords.top),
        );
      }
    }
  }, [item, getCanvasUnits, theme.palette.tertiary.dark]);

  const toggleTextOnly = (event, value) => {
    if (!canvas.current) {
      return;
    }
    canvas.current.nextSibling.querySelector('canvas').style.visibility = value
      ? 'hidden'
      : 'visible';
    const spans = canvas.current.parentElement.querySelectorAll('span');
    spans.forEach(e => {
      e.style.color = value ? '#000' : 'transparent';
    });
  };

  const start = useRef([0, 0]);
  const end = useRef([0, 0]);
  const isDown = useRef(false);

  const getMouseCoords = e => {
    const bounds = canvas.current.getBoundingClientRect();
    return [
      (e.offsetX / bounds.width) * canvas.current.width,
      (e.offsetY / bounds.height) * canvas.current.height,
    ];
  };

  const getClientCoords = ([x1, y1], [x2, y2]) => {
    const [left, right] = [x1, x2].sort(numSort);
    const [top, bottom] = [y1, y2].sort(numSort);
    const bounds = canvas.current.getBoundingClientRect();
    return {
      left: (left * bounds.width) / canvas.current.width,
      top: (top * bounds.height) / canvas.current.height,
      right: (right * bounds.width) / canvas.current.width,
      bottom: (bottom * bounds.height) / canvas.current.height,
    };
  };

  const handleMouseDown = useCallback(
    e => {
      if (!selecting) {
        isDown.current = false;
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      // get references to the canvas and context
      const ctx = canvas.current.getContext('2d');
      ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);
      const selection = window.getSelection();
      selection.removeAllRanges();
      setItem({ ...item, coords: null });

      // save the starting x/y of the rectangle
      start.current = getMouseCoords(e);

      // set a flag indicating the drag has begun
      isDown.current = true;
    },
    [item, selecting],
  );

  const handleMouseUp = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      toggleTextOnly(e, false);
      setSelecting(false);

      // the drag is over, clear the dragging flag
      isDown.current = false;
      let selected;
      try {
        selected = rectangleSelect(
          canvas.current.nextSibling.querySelectorAll('span'),
          getClientCoords(start.current, end.current),
        );
      } catch (error) {
        if (error.message === 'You selected multiple lines') {
          setItem({ ...item, error: `ERROR: ${error.message}` });
        }
      }

      if (!selected) {
        return;
      }

      const selectedText = document.getSelection().toString().trim();
      if (!selectedText) {
        setItem({ ...item, error: 'You should select whole words' });
        return;
      }
      if (!selectedText?.match(/^\w[\w-/]+\w$/)) {
        setItem({
          ...item,
          error:
            'ERROR: The text must start and end with a letter or number and only include letters, numbers, hyphens, or slashes.',
        });
        return;
      }
      const { join, pos } = getPos(selectedText, reverse);
      if (pos === null) {
        setItem({ ...item, error: 'ERROR: You should select the whole word' });
        return;
      }
      document.getSelection().getRangeAt(0);
      if (item?.selector === 'box') {
        const newItem = {
          ...item,
          ...(pos ? { position: pos } : {}),
          ...(join ? { join } : {}),
          coords: {
            left: getPageUnits(start.current[0]),
            top: getPageUnits(start.current[1]),
            right: getPageUnits(end.current[0]),
            bottom: getPageUnits(end.current[1]),
          },
        };
        delete newItem.error;
        setItem(newItem);
        setFoundItems(fi => ({
          ...fi,
          [item.name]: selectedText,
        }));
      }
    },
    [item, reverse, getPageUnits],
  );

  const handleMouseOut = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    // the drag is over, clear the dragging flag
    isDown.current = false;
  }, []);

  const handleMouseMove = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      // if we're not dragging, just return
      if (!selecting || !isDown.current) {
        return;
      }

      // get the current mouse position
      const [mouseX, mouseY] = getMouseCoords(e);

      if (!mouseX && !mouseY) {
        // Weird error in Firefox where mouse coords are zero
        return;
      }

      // Put your mousemove stuff here

      // get references to the canvas and context
      const ctx = canvas.current.getContext('2d');

      // style the context
      const dpr = window.devicePixelRatio || 1;
      ctx.strokeStyle = theme.palette.primary.main;
      ctx.lineWidth = 1.5 * dpr;
      // clear the canvas
      ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);

      // calculate the rectangle width/height based
      // on starting vs current mouse position
      const width = mouseX - start.current[0];
      const height = mouseY - start.current[1];
      end.current = [mouseX, mouseY];

      // draw a new rect from the start position
      // to the current mouse position
      ctx.strokeRect(start.current[0], start.current[1], width, height);
    },
    [selecting, theme.palette.primary.main],
  );

  const handleMouseMoveThrottled = throttle(handleMouseMove, 100);

  useEffect(() => {
    const currentCanvas = canvas.current;

    if (item?.selector !== 'box') {
      if (handlersStatus !== 'setting') {
        setHandlersStatus('setting');
      }
      return () => {};
    }

    if (!currentCanvas) {
      if (handlersStatus !== 'not set') {
        setHandlersStatus('not set');
        setScale(null);
      }
      return () => {};
    }
    if (handlersStatus === 'set') {
      currentCanvas.addEventListener('mousedown', handleMouseDown);
      currentCanvas.addEventListener('mousemove', handleMouseMoveThrottled);
      currentCanvas.addEventListener('mouseup', handleMouseUp);
      currentCanvas.addEventListener('mouseout', handleMouseOut);
      return () => {
        currentCanvas.removeEventListener('mousedown', handleMouseDown);
        currentCanvas.removeEventListener('mousemove', handleMouseMoveThrottled);
        currentCanvas.removeEventListener('mouseup', handleMouseUp);
        currentCanvas.removeEventListener('mouseout', handleMouseOut);
        setHandlersStatus('setting');
      };
    }
    if (!currentCanvas && handlersStatus === 'setting') {
      setHandlersStatus('not set');
      setTimeout(() => setHandlersStatus('setting'), 500);
      return () => {};
    }

    setHandlersStatus('set');

    return () => {};
  }, [scale, handlersStatus, item?.selector, selectedItem, reverse, selecting]);

  const handleScaleChange = s => {
    setScale(s);
  };

  const handleSave = async () => {
    setStatus('saving');
    navigateBack();
    await dispatch(
      documentFormats.update(format._id, {
        items: Object.values(items),
      }),
    );
    setStatus(null);
  };

  const handleXpath = xml => async xpath => {
    if (selecting) {
      const newItem = {
        ...item,
        selector: 'xpath',
        xpath,
      };
      delete newItem.error;
      setItem(newItem);
      const value = await getXPathValue(xml, xpath);
      setFoundItems(fi => ({
        ...fi,
        [newItem.name]: value,
      }));
      setSelecting(false);
    }
  };

  const handleXpathChange = async e => {
    setItem({ ...item, xpath: e.target.value });
  };

  const handleDirection = () => {
    const selectedText = document.getSelection().toString().trim();
    const it = { ...item };
    const { pos } = getPos(selectedText, !reverse);
    if (pos) {
      it.position = pos;
    } else {
      delete it.position;
    }
    setItem(it);
    setReverse(!reverse);
  };

  const separatorRegex = new RegExp(`[${item?.separators}]+`);
  const fileNameParts =
    (item?.separators
      ? currentFile?.file?.name.split(separatorRegex)
      : [currentFile?.file?.name]) || [];

  const position = isNil(item?.position) || item.position === '' ? NaN : Number(item.position);
  const hasPosition = Number(position) >= 0;
  const itemNames = [format.sendBy || 'code', 'dni', 'year', 'month', 'day'];

  if (hasDivisions) {
    itemNames.unshift('division');
  }

  if (format.type === 'xml') {
    itemNames.unshift('filename');
  }

  const disabled = itemNames.some(
    name =>
      (items[name]?.selector === 'fileName' && (!hasPosition || !items[name]?.separators)) ||
      (items[name]?.selector === 'xpath' && !items[name]?.xpath) ||
      (items[name]?.selector === 'coords' && !items[name]?.coords) ||
      (items[name]?.selector === 'box' &&
        (!foundItems[name] || !items[name]?.coords || items[name]?.error)),
  );

  const handleItemChange = it => {
    setItems(oldItems => {
      const newItems = { ...oldItems };
      if (it.selected) {
        newItems[it.name] = { ...it };
        delete newItems[it.name].selected;
        delete newItems[it.name].join;
      } else {
        delete foundItems[it.name];
        delete newItems[it.name];
      }
      return newItems;
    });
  };

  return (
    <>
      <div className={classes.content}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className={classnames(classes.blanket, { [classes.selecting]: selecting })}
          onClick={e => {
            toggleTextOnly(e, false);
            setSelecting(false);
          }}
        />
        <div className={classes.controls}>
          {format && (
            <div key={formatId}>
              <table className={classes.items}>
                <tbody>
                  <tr>
                    {itemNames.map(it => {
                      return (
                        <td key={it}>
                          <ItemCheckBox
                            value={items[it]}
                            found={foundItems[it]}
                            name={it}
                            onClick={() => {
                              setSelectedItem(it);
                            }}
                            onChange={handleItemChange}
                            onSelectionDrag={e => {
                              setSelectedItem(it);
                              if (['single-pdf', 'multi-pdf'].includes(format.type)) {
                                toggleTextOnly(e, true);
                              }
                              setSelecting(true);
                            }}
                            selected={selectedItem === it}
                            type={format.type}
                          />
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
              <div>
                {user.isSuperAdmin && selectedItem && (
                  <div>
                    <div className={classes.selectWrapper}>
                      <Autosuggest
                        fullWidth
                        value={item?.selector}
                        onChange={v => {
                          setItem({ ...item, selector: v.value });
                        }}
                        suggestions={['box', 'xpath', 'fileName', 'coords', 'user'].map(value => ({
                          value,
                          label: startCase(value),
                        }))}
                        className={classes.select}
                      />
                      {item?.selector === 'box' && (
                        <IconButton
                          onClick={handleDirection}
                          className={classes.iconButton}
                          size="large"
                        >
                          {reverse ? (
                            <FormatTextdirectionRToLIcon />
                          ) : (
                            <FormatTextdirectionLToRIcon />
                          )}
                        </IconButton>
                      )}
                    </div>
                    {item?.selector === 'user' && (
                      <div className={classes.selectWrapper}>
                        <Autosuggest
                          isClearable
                          fullWidth
                          value={item?.defaultValue}
                          className={classes.select}
                          clearValue={() => {
                            setItem({ ...item, defaultValue: '' });
                          }}
                          onChange={v => {
                            setItem({ ...item, defaultValue: v?.value });
                          }}
                          suggestions={['currentMonth', 'previousMonth'].map(value => ({
                            value,
                            label: startCase(value),
                          }))}
                        />
                      </div>
                    )}
                    {item?.selector === 'box' && (
                      <CopyField
                        className={classes.input}
                        value={
                          item &&
                          JSON.stringify(pick(item, ['error', 'coords', 'position', 'join']))
                        }
                      />
                    )}
                    {item?.selector === 'xpath' && (
                      <div>
                        <TextField
                          fullWidth
                          value={item.xpath}
                          onChange={handleXpathChange}
                          className={classes.input}
                        />
                      </div>
                    )}
                    {item?.selector === 'coords' && (
                      <div>
                        <TextField
                          fullWidth
                          value={item.coords}
                          onChange={e => {
                            setItem({ ...item, coords: e.target.value });
                          }}
                          className={classes.input}
                        />
                      </div>
                    )}
                    {item?.selector === 'fileName' && (
                      <div>
                        <TextField
                          fullWidth
                          placeholder="Separators"
                          value={item.separators}
                          onChange={e => {
                            setItem({ ...item, separators: e.target.value });
                          }}
                          className={classes.input}
                        />
                        <TextField
                          fullWidth
                          placeholder="Position"
                          value={item.position}
                          onChange={e => {
                            setItem({ ...item, position: e.target.value });
                          }}
                          type="number"
                          className={classes.input}
                        />
                        <div className={classes.fileName}>
                          {fileNameParts.map((part, idx) => (
                            <>
                              {idx > 0 && (
                                <Typography display="inline" component="span">
                                  {' '}
                                </Typography>
                              )}
                              <Typography
                                display="inline"
                                component="span"
                                className={classnames({
                                  [classes.partSelected]: hasPosition && idx === +item.position,
                                })}
                                key={idx}
                              >
                                {part}
                              </Typography>
                            </>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={classes.pdf}>
          {['single-pdf', 'multi-pdf'].includes(format.type) && (
            <PdfPreview
              onScaleChange={handleScaleChange}
              renderTextLayer={false}
              canvasRef={canvas}
              src={currentFile.file}
              showSignature={false}
              pageNum={currentPage}
              fullWidth
            />
          )}
          {format.type === 'xml' && (
            <XmlPreview xml={currentFile.xml} onClick={selecting && handleXpath(currentFile.xml)} />
          )}
        </div>
      </div>
      <div className={classes.navigationButtons}>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={disabled || status === 'saving'}
          className={classes.save}
        >
          {status === 'saving' ? 'Saving...' : 'Save'}
        </Button>
        <Button
          onClick={navigateBack}
          color="secondary"
          variant="outlined"
          className={classes.save}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default memo(HrBotConfig);
