import Box from '@mui/material/Box';
import withStyles from '@mui/styles/withStyles';
import imageCompression from 'browser-image-compression';
import ChatPanelContext from 'components/@home/@messages/ChatPanel/ChatPanelContext';
import ForwardMessagesModal from 'components/@home/@messages/ChatPanel/ForwardMessagesModal';
import JoinToParticipate from 'components/@home/@messages/ChatPanel/JoinToParticipate';
import PinnedMessage from 'components/@home/@messages/ChatPanel/PinnedMessage';
import ImageUploadPreview from 'components/@home/@messages/ChatPanel/SendForm/ImageUploadPreview';
import ErrorAlert from 'components/alerts/ErrorAlert';
import deferRender from 'defer-render-hoc';
import isEmpty from 'lodash/isEmpty';
import { bool, object, string } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import authCompanyId from 'store/selectors/authCompanyId';
import currentChannelIdSelector from 'store/selectors/currentChannelId';
import Attachments from 'services/api/Attachments';
import channelShape from 'shapes/channel';
import uniqid from 'uniqid';
import parseFileName from 'utils/getFileExtension';
import ChatHeader from './ChatHeader';
import connector from './connector';
import MessagesList from './MessagesList';
import MessagesWrapper from './MessagesWrapper';
import ReadOnly from './ReadOnly';
import SendForm from './SendForm';

const styles = theme => ({
  hidden: {
    display: 'none',
  },
  root: {
    backgroundColor: theme.palette.secondary.ultraLight,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
});

const ChatPanel = ({
  classes,
  channel,
  isCompanyAdmin,
  isMember,
  actions,
  locale,
  authEmployee,
}) => {
  const navigate = useNavigate();
  const currentId = useSelector(currentChannelIdSelector);
  const [errorMessage, setErrorMessage] = useState('');
  const [imagesToUpload, setImagesToUpload] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({});
  const [sentAsChannel, setSentAsChannel] = useState(false);
  const cancelRef = useRef(() => {});
  const companyId = useSelector(authCompanyId);

  const handleUnpin = async message => {
    await actions.messages.unpin(message);
  };

  const sendMessage = useCallback(
    async ({ text = '', mentions, attachments }) => {
      const msg = {
        _id: uniqid(),
        text,
        mentions:
          mentions?.map(m => ({
            pos: [m.plainTextIndex, m.plainTextIndex + m.display.length - 1],
            employee: m.id,
          })) || [],
        title: undefined,
        acknowledges: [],
        attachments,
        channel_id: channel._id,
        company_id: channel.company,
        previews: {},
        createdAt: Date.now(),
        employee_id: authEmployee?._id,
        user_id: authEmployee?.user?._id,
        isDeletedBy: [],
        isDraft: false,
        isEdited: false,
        isMandatory: false,
        temp: true,
        seen: false,
        senderWasDeleted: false,
        sentAsChannel,
      };

      await actions.messages.send(msg);
    },
    [
      actions.messages,
      authEmployee?._id,
      authEmployee?.user?._id,
      channel?._id,
      channel?.company,
      sentAsChannel,
    ],
  );

  const onUploadProgress =
    idx =>
    ({ total, loaded }) => {
      setUploadProgress(p => ({ ...p, [idx]: loaded / total }));
    };

  const getCancelFunc = onCancel => {
    cancelRef.current = onCancel;
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], { type: mimeString });
  }

  const upload = useCallback(
    async ({ file, src, caption }, idx) => {
      const { type } = file;
      let compressedFile;
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1600,
        useWebWorker: true,
        fileType: 'image/jpeg',
      };
      if (type.startsWith('image/') && type !== 'image/gif') {
        const { name } = parseFileName(file.name);
        const newFileName = `${name}.jpg`;
        const blob = dataURItoBlob(src);
        compressedFile = new File([blob], newFileName, {
          type: 'image/jpeg',
        });
        compressedFile = await imageCompression(compressedFile, options);
      }
      const attachment = await Attachments.upload(
        companyId,
        channel._id,
        compressedFile || file,
        locale,
        onUploadProgress(idx),
        getCancelFunc,
      );
      return { attachments: [attachment], text: caption };
    },
    [channel?._id, channel?.company, locale],
  );

  const handleError = error => {
    let msg = I18n.t('AttachFile.Errors.Unknown');
    setTimeout(() => setErrorMessage(''), 15000);
    if (error.data?.type === 'ValidationError') {
      msg = error.data.message;
    } else if (error.message === 'Canceled') {
      msg = I18n.t('AttachFile.Errors.Canceled');
    }
    setUploading(false);
    setErrorMessage(msg);
  };

  const handleSend = useCallback(async () => {
    try {
      setUploading(true);
      const messages = await Promise.all(imagesToUpload.map(upload));
      // eslint-disable-next-line no-restricted-syntax
      for (const message of messages) {
        // eslint-disable-next-line no-await-in-loop
        await sendMessage(message);
      }
    } catch (error) {
      console.log(error);
      handleError(error);
    } finally {
      setImagesToUpload([]);
      setUploading(false);
    }
  }, [imagesToUpload, sendMessage, upload]);

  const setProps = useCallback(
    (props, idx) => {
      setImagesToUpload(images => {
        const newImages = [...images];
        newImages[idx] = { ...newImages[idx], ...props };
        return newImages;
      });
    },
    [setImagesToUpload],
  );

  const scrollToMessage = async message => {
    const messageId = message._id;
    navigate(`/home/messages/${channel._id}`, { state: { messageId } });
    const loadMessages =
      (!messageId && channel && !channel.complete) ||
      (messageId && !channel.messages_ids.includes(messageId));
    setTimeout(() => {
      if (loadMessages) {
        actions.messages.load(channel._id, { aroundId: messageId });
      }
    }, 10);
  };

  const inArticle = useMatch('/home/messages/:id/article/:articleId');

  if (!channel) return null;

  const progressArr = Object.values(uploadProgress);
  const progress = (progressArr.reduce((acc, curr) => acc + curr, 0) / progressArr.length) * 100;
  if (!currentId) {
    return null;
  }

  return (
    <>
      <Outlet />
      <Box className={inArticle ? classes.hidden : classes.root}>
        <ChatPanelContext.Provider value={{ imagesToUpload, setImagesToUpload, setProps }}>
          <div>
            <ChatHeader />
            <PinnedMessage
              onClick={scrollToMessage}
              message={channel.pinnedMessage}
              onUnpin={handleUnpin}
              canUnpin={channel.isAdmin || channel.isDirect}
            />
          </div>
          {isEmpty(imagesToUpload) ? (
            <>
              <MessagesWrapper>
                <MessagesList scrollToMessage={scrollToMessage} />
              </MessagesWrapper>
              {isCompanyAdmin && !isMember && <JoinToParticipate />}
              {channel.isReadOnly ? (
                <ReadOnly />
              ) : (
                <SendForm onChangeSentAsChannel={setSentAsChannel} />
              )}
              {(isCompanyAdmin || channel.isAdmin || channel.canWriteAsChannel) &&
                channel.isReadOnly && <SendForm onChangeSentAsChannel={setSentAsChannel} />}
            </>
          ) : (
            <ImageUploadPreview onSend={handleSend} isUploading={isUploading} progress={progress} />
          )}
          <ErrorAlert isOpen={!!errorMessage} label={I18n.t('AttachFile.Errors.Title')}>
            {errorMessage}
          </ErrorAlert>
          <ForwardMessagesModal />
        </ChatPanelContext.Provider>
      </Box>
    </>
  );
};

ChatPanel.propTypes = {
  classes: object.isRequired,
  channel: channelShape,
  isCompanyAdmin: bool.isRequired,
  isMember: bool,
  actions: object.isRequired,
  locale: string.isRequired,
  authEmployee: object.isRequired,
};

ChatPanel.defaultProps = {
  channel: null,
  isMember: false,
};

export default withStyles(styles)(deferRender(connector(ChatPanel)));
