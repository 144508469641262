/** @flow */
import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import LauncherAppLinkDrawerContent from 'components/@home/drawers/LauncherAppDrawer/LauncherAppLinkDrawerContent';
import LauncherAppSortableLinkCard from 'components/@home/drawers/LauncherAppDrawer/LauncherAppSortableLinkCard';
import ControlLabel from 'components/common/ControlLabel/ControlLabel';
import DrawerProvider from 'components/common/Drawer/DrawerProvider';
import Autosuggest from 'components/controls/Autosuggest';
import SecretField from 'components/controls/SecretField';
import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import AppData from 'services/api/AppData';
import { LAUNCHER_APP_ID } from 'services/constants';
import { useLazyListTeamsQuery } from 'store/app/entities/TeamsSlice';
import authCompany from 'store/selectors/authCompany';
import languages from 'utils/languages';
import loadOptionsLazyQuery from 'utils/loadOptionsLazyQuery';

const styles = {
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 1,
    padding: 1,
  },
  button: {
    borderRadius: 1,
    border: '1px dashed',
    borderColor: 'divider',
    width: 140,
    p: 4,
    mr: 2.5,
  },
  delete: {
    mr: 'auto',
  },
  form: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'min-content auto',
    flexDirection: 'column',
    gap: 3,
    borderColor: 'divider',
    alignItems: 'center',
    '& label p': {
      whiteSpace: 'nowrap',
    },
    mb: 2,
  },
  languages: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: 1,
    rowGap: 3,
  },
};
const SecretFieldWrapper = forwardRef(({ ...props }, ref) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <SecretField ref={ref} {...props} />
    </Box>
  );
});

const getLanguagesMap = lang =>
  languages[lang].reduce((acc, { value, label }) => ({ ...acc, [value]: label }), {});

const formatOptionLabel = option => (
  <Box
    sx={{
      whiteSpace: 'pre',
      width: 'calc(100%)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}
  >
    {'   '.repeat(option.level)}
    {option.label}
  </Box>
);

const LauncherAppPreview = ({ save }: { save: Function }) => {
  const [triggerListTeamsQuery] = useLazyListTeamsQuery();
  const company = useSelector(authCompany);
  const locale = useSelector(state => state.i18n.locale);
  const languagesMap = getLanguagesMap(locale);
  const methods = useFormContext();
  const { fields, move, remove, append } = useFieldArray({ name: 'links' });
  const onClose = useCallback(() => {
    move(0, 0); // Update fields
  }, [move]);
  const [activeId, setActiveId] = useState(null);
  const activeLink = useMemo(() => {
    return fields.find(f => f.id === activeId);
  }, [activeId, fields]);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(MouseSensor),
    useSensor(TouchSensor),
  );

  const handleDragStart = event => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      const oldIndex = fields.findIndex(i => i.id === active.id);
      const newIndex = fields.findIndex(i => i.id === over.id);
      move(oldIndex, newIndex);
      return;
    }

    setActiveId(null);
  };

  const handleDragCancel = () => {
    setActiveId(null);
  };

  const handleRemove = useCallback(
    (index, hide) => () => {
      hide();
      remove(index);
      save();
    },
    [remove, save],
  );

  const handleGenerateSecret = useCallback(async () => {
    const { secret } = await AppData.regenerateSecret(LAUNCHER_APP_ID);
    methods.setValue('signingSecret', secret);
  }, [methods]);

  return (
    <>
      <DrawerProvider
        title={I18n.t('LauncherApp.linkDrawer.title')}
        onClose={onClose}
        onSubmit={save}
        name="LauncherAppLinkDrawer"
        actions={({ hide, state }) => (
          <>
            <Button
              variant="outlined"
              color="error"
              sx={styles.delete}
              onClick={handleRemove(state, hide)}
            >
              <Translate value="Delete" />
            </Button>
            <Button type="submit" variant="contained" color="primary">
              <Translate value="Save" />
            </Button>
          </>
        )}
        drawerContent={<LauncherAppLinkDrawerContent />}
      >
        {({ show }) => (
          <>
            <Box sx={styles.form}>
              <Controller
                name="teams"
                render={({ field: { value, onChange, ...rest } }) => (
                  <ControlLabel
                    control={Autosuggest}
                    isMulti
                    isClearable
                    value={value}
                    onChange={values => {
                      onChange({
                        target: {
                          value: values.map(({ value: v }) => v),
                          name: rest.name,
                        },
                      });
                    }}
                    {...rest}
                    autoWidth
                    formatOptionLabel={formatOptionLabel}
                    label={I18n.t('LauncherApp.fields.link.teams')}
                    loadOptions={loadOptionsLazyQuery}
                    additional={{
                      page: 1,
                      trigger: triggerListTeamsQuery,
                    }}
                  />
                )}
              />
              <ControlLabel
                labelPrefix="LauncherApp.fields"
                {...methods.register('signingSecret')}
                fullWidth
                size="small"
                control={SecretFieldWrapper}
                confirmRegenerate={handleGenerateSecret}
              />
              {company?.languages?.length > 0 && (
                <>
                  <Typography component="label" htmlFor={`name.${company?.languages?.[0]}`}>
                    {I18n.t('LauncherApp.fields.name')}
                  </Typography>
                  <Box sx={styles.languages}>
                    {company?.languages?.map(lang => (
                      <TextField
                        key={lang}
                        id={`name.${lang}`}
                        label={languagesMap[lang]}
                        {...methods.register(`name.${lang}`)}
                        size="small"
                      />
                    ))}
                  </Box>
                </>
              )}
              <Typography>{I18n.t('LauncherApp.links')}</Typography>
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
              >
                <SortableContext items={fields} strategy={rectSortingStrategy}>
                  <Box sx={styles.grid}>
                    {fields.map((link, index) => (
                      <LauncherAppSortableLinkCard
                        key={link.id}
                        link={link}
                        index={index}
                        onClick={() => show(index)}
                      />
                    ))}
                    <Button
                      sx={styles.button}
                      onClick={() => {
                        append({});
                        show(fields.length);
                      }}
                    >
                      {I18n.t('LauncherApp.addLink')}
                    </Button>
                  </Box>
                </SortableContext>

                <DragOverlay adjustScale>
                  {activeId ? <LauncherAppSortableLinkCard link={activeLink} overlay /> : null}
                </DragOverlay>
              </DndContext>
            </Box>
          </>
        )}
      </DrawerProvider>
    </>
  );
};

export default LauncherAppPreview;
