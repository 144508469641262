/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, SvgIcon, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import UploadIcon from 'assets/icons/upload.svg';
import cx from 'classnames';
import Loading from 'components/common/Loading';
import { bool, func, node, object, string } from 'prop-types';
import React from 'react';
import Dropzone from 'react-dropzone';
import { I18n } from 'react-redux-i18n';

const useStyles = makeStyles(theme => {
  return {
    icon: {
      color: theme.palette.greyBlue,
      fontSize: '40px',
    },
    input: {
      display: 'none',
    },
    dropzone: {
      borderRadius: 4,
      padding: ({ hasImage }) => (hasImage ? 0 : '50px 60px 50px 60px'),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#000',
      cursor: 'pointer',
      outlineColor: theme.palette.primary.main,
      width: '100%',
      height: '100%',
      border: '2px solid transparent',
    },
    activeDropzone: {
      background: theme.palette.primary.lighter,
      borderColor: theme.palette.primary.main,
    },
    rejectDropzone: {
      background: '#ffeeee',
      borderColor: theme.palette.danger,
    },
  };
});

const DropzoneBox = ({ sx, onDrop, loading, error, accept, multiple, image, placeholder }) => {
  const classes = useStyles({ hasImage: !!image });
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 210,
        width: '100%',
        backgroundImage: `url(${image})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        ...sx,
      }}
    >
      {loading ? (
        <Loading size={80} />
      ) : (
        <Dropzone
          accept={accept}
          onDrop={onDrop}
          disableClick
          onClick={e => e.preventDefault()}
          multiple={multiple}
        >
          {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
            const uploadPlaceholder = placeholder || (
              <>
                <SvgIcon className={classes.icon}>
                  <UploadIcon />
                </SvgIcon>
                <Typography gutterBottom>
                  {I18n.t('AcknowledgementCreate.Drag & drop file here or')}
                </Typography>
                <Button variant="contained" color="primary" size="small">
                  {I18n.t('AcknowledgementCreate.Browse file')}
                </Button>
              </>
            );
            return (
              <div
                {...getRootProps()}
                className={cx(classes.dropzone, {
                  [classes.activeDropzone]: isDragAccept,
                  [classes.rejectDropzone]: isDragReject,
                })}
              >
                <input {...getInputProps()} />
                {isDragAccept && <p>{I18n.t('AcknowledgementCreate.Drop the file to upload')}</p>}
                {isDragReject && <p>{I18n.t('AcknowledgementCreate.File type not accepted')}</p>}
                {!isDragActive && !image && uploadPlaceholder}
              </div>
            );
          }}
        </Dropzone>
      )}
      {error && (
        <Typography color="error" align="center">
          {I18n.t('Something went wrong with sending data')}
        </Typography>
      )}
    </Box>
  );
};

DropzoneBox.propTypes = {
  onDrop: func.isRequired,
  loading: bool,
  error: bool,
  accept: string,
  multiple: bool,
  image: string,
  sx: object,
  placeholder: node,
};

DropzoneBox.defaultProps = {
  accept: 'application/pdf',
  multiple: false,
  loading: false,
  error: false,
  image: null,
  sx: {},
  placeholder: null,
};

export default DropzoneBox;
