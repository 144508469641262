import { Button, Menu, MenuItem, SvgIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DotsIcon from 'assets/icons/dots.svg';
import DeleteDialog from 'components/common/DeleteDialog';
import { bool, object, string } from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { useNavigate } from 'react-router-dom';
import channelShape from 'shapes/channel';
import openAppConfig from 'utils/openAppConfig';
import useOpenClose from 'utils/useOpenClose';
import connector from './connector';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 1px 2px 0 rgba(2, 2, 3, 0.05)',
    border: `solid 1px ${theme.palette.divider}`,
    padding: '0 10px',
  },
  label: {
    color: 'black',
  },
  icon: {
    fontSize: 18,
    marginRight: 12,
    marginLeft: 7,
    color: theme.palette.primary.main,
  },
  danger: {
    color: theme.palette.error.dark,
  },
}));

const DetailsButton = ({
  actions,
  company,
  channel,
  archived,
  authEmployee,
  app,
  isCompanyAdmin,
  isTeamAdmin,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenDialog, openDialog, closeDialog] = useOpenClose();
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpenDialog) {
      setAnchorEl(null);
    }
  }, [isOpenDialog]);

  const confirmDelete = useCallback(() => {
    actions.channels.destroy(channel._id);
    setTimeout(() => navigate(`/home/messages`), 10);
    closeDialog();
  }, [actions.channels, channel._id, closeDialog, navigate]);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleEdit = useCallback(() => {
    actions.drawers.channel.open({ type: 'edit' });
    setAnchorEl(null);
  }, [actions]);

  const handlePin = useCallback(() => {
    actions.channels.setPinned(company._id, authEmployee._id, channel._id, !channel.pinned);
    setAnchorEl(null);
  }, [actions.channels, company._id, authEmployee._id, channel._id, channel.pinned]);

  const handleArchive = useCallback(() => {
    actions.channels.setArchived(company._id, authEmployee._id, channel._id, !archived);
    setAnchorEl(null);
  }, [actions.channels, company._id, authEmployee._id, channel._id, archived]);

  const openMedia = useCallback(
    type => () => {
      actions.drawers.media.open({ type });
      setAnchorEl(null);
    },
    [actions],
  );

  const openMembers = useCallback(() => {
    actions.drawers.employeeList.open();
    setAnchorEl(null);
  }, [actions]);

  const openApp = useCallback(() => {
    setAnchorEl(null);
    openAppConfig(actions, app)();
  }, [actions, app]);

  const { isDirect, name } = channel;

  return (
    <div>
      <Button
        classes={{ root: classes.root, label: classes.label }}
        aria-owns={anchorEl ? 'simple-menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <SvgIcon className={classes.icon}>
          <DotsIcon />
        </SvgIcon>
        {I18n.t('DetailsDropdown.Details')}
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={openMedia('media')}>{I18n.t('DetailsDropdown.Media')}</MenuItem>
        <MenuItem onClick={openMedia('doc')}>{I18n.t('DetailsDropdown.Documents')}</MenuItem>
        <MenuItem onClick={openMedia('link')}>{I18n.t('DetailsDropdown.Links')}</MenuItem>
        <MenuItem onClick={openMedia('form')}>{I18n.t('DetailsDropdown.Forms')}</MenuItem>
        {!isDirect && (isCompanyAdmin || channel.isAdmin || !channel.isReadOnly)
          ? [
              <MenuItem key="members" onClick={openMembers}>
                {I18n.t('DetailsDropdown.Members')}
              </MenuItem>,
            ]
          : null}
        <MenuItem key="edit" onClick={handleArchive}>
          {I18n.t(archived ? 'DetailsDropdown.Unarchive' : 'DetailsDropdown.Archive')}
        </MenuItem>
        {isCompanyAdmin && !channel.isDirect && (
          <MenuItem key="pin" onClick={handlePin}>
            {I18n.t(channel.pinned ? 'DetailsDropdown.Unpin' : 'DetailsDropdown.Pin')}
          </MenuItem>
        )}
        {(isCompanyAdmin || channel.isAdmin) && !isDirect
          ? [
              <MenuItem key="edit" onClick={handleEdit}>
                {I18n.t('DetailsDropdown.Edit Channel')}
              </MenuItem>,
              <MenuItem key="delete" onClick={openDialog} className={classes.danger}>
                {I18n.t('DetailsDropdown.Delete Channel')}
              </MenuItem>,
            ]
          : null}
        {isTeamAdmin && app && (
          <MenuItem key="app" onClick={openApp}>
            {I18n.t('DetailsDropdown.Open app')}
          </MenuItem>
        )}
      </Menu>
      <DeleteDialog
        typeBack={name}
        message={I18n.t('DetailsDropdown.ConfirmDelete', { name })}
        isOpen={isOpenDialog}
        onConfirm={confirmDelete}
        onClose={closeDialog}
      />
    </div>
  );
};

DetailsButton.propTypes = {
  app: string,
  actions: object.isRequired,
  isCompanyAdmin: bool.isRequired,
  isTeamAdmin: bool.isRequired,
  channel: channelShape.isRequired,
  archived: bool.isRequired,
  company: object.isRequired,
  authEmployee: object.isRequired,
};

DetailsButton.defaultProps = {
  app: null,
};

const isEqual = (prev, next) => {
  return (
    prev.isCompanyAdmin === next.isCompanyAdmin &&
    prev.channel.isDirect !== next.channel.isDirect &&
    prev.channel.name === next.channel.name &&
    prev.channel.pinned === next.channel.pinned &&
    prev.channel._id === next.channel._id
  );
};

export default connector(memo(DetailsButton, isEqual));
