/* eslint-disable react/no-access-state-in-setstate */
import { Table, TableBody } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Loading from 'components/common/Loading';
import Pagination from 'components/controls/Pagination';
import { connect } from 'formik';
import { arrayOf, func, object, string } from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Company from 'services/api/Company';
import { useListQuery } from 'store/app/entities/EmployeesApiSlice';
import authCompanyId from 'store/selectors/authCompanyId';
import authEmployee from 'store/selectors/authEmployee';
import EmployeeRow from './EmployeeRow';
import TableHead from './TableHead';
import Toolbar from './Toolbar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    marginTop: theme.spacing(3),
  },
}));

const limit = 10;

const union = (value, added, by, base) => {
  return [
    ...value,
    ...added
      .filter(a => !value.some(v => (by ? v[by] : v) === a))
      .map(a => (by ? { ...base, [by]: a } : a)),
  ];
};

const EmployeesTable = ({ error, value, defaultRole, name, onChange, formik: { values } }) => {
  const classes = useStyles();
  const [order, setOrder] = React.useState({ by: 'name', isReverse: false });
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = React.useState('');
  const companyId = useSelector(authCompanyId);
  const {
    data: { data: employeesWithMe, metadata } = {},
    isLoading,
    isFetching,
  } = useListQuery({
    companyId,
    search,
    skip: page * limit,
    limit,
    order: (order.isReverse ? '-' : '') + order.by,
    team: values._id,
  });
  const employee = useSelector(authEmployee);
  const employees = useMemo(
    () => employeesWithMe?.filter(e => e._id !== employee?._id),
    [employee?._id, employeesWithMe],
  );

  const selectAll = async enabled => {
    const data = await Company.getAdminEmployeesIds(search);
    if (enabled) {
      onChange(name, union(values[name], data, 'employee', { role: defaultRole }));
      onChange(
        `${name}Removed`,
        values[`${name}Removed`].filter(e => value.some(v => v === e)),
      );
      onChange(
        `${name}Added`,
        union(values[`${name}Added`] || [], data, 'employee', { role: defaultRole }),
      );
    } else {
      if (search) {
        onChange(
          name,
          values[name].filter(v => !data.includes(v.employee)),
        );
        onChange(
          `${name}Added`,
          values[`${name}Added`].filter(v => !data.includes(v.employee)),
        );
      } else {
        onChange(name, []);
        onChange(`${name}Added`, []);
      }
      onChange(`${name}Removed`, union(values[`${name}Removed`], data));
    }
  };

  const toggleSelect = e => {
    if (value.find(member => member.employee === e)) {
      onChange(
        name,
        value.filter(member => member.employee !== e),
      );
      onChange(`${name}Removed`, [...values[`${name}Removed`], e]);
      onChange(
        `${name}Added`,
        values[`${name}Added`].filter(v => v.employee !== e),
      );
    } else {
      onChange(name, [...value, { employee: e, role: defaultRole }]);
      onChange(
        `${name}Removed`,
        values[`${name}Removed`].filter(_id => _id !== e),
      );
      onChange(`${name}Added`, [...values[`${name}Added`], { employee: e, role: defaultRole }]);
    }
  };

  const sort = by => {
    setOrder(currentOrder => ({ by, isReverse: !currentOrder.isReverse }));
  };

  const onSearch = newSearch => {
    setSearch(newSearch);
    setPage(0);
  };

  const changePage = ({ selected }) => {
    setPage(selected);
  };

  const setRole = (e, role) => {
    const newValue = value.map(m => (m.employee === e._id ? { ...m, role } : m));
    onChange(name, newValue);
    const newValueAdded = [
      ...values[`${name}Added`].filter(m => m.employee !== e._id),
      { ...values[name].find(m => m.employee === e._id), role },
    ];
    onChange(`${name}Added`, newValueAdded);
  };

  if (isLoading) {
    return <Loading />;
  }

  const selectedCount = value.length;

  const employeesCount = metadata?.total || 0;

  return (
    <div className={classes.root}>
      <Toolbar error={error} selected={selectedCount} onSearch={onSearch} isFetching={isFetching} />
      <Table className={classes.table} aria-labelledby="employeesTable">
        <TableHead
          order={order}
          selected={selectedCount}
          rows={employeesCount}
          onSort={sort}
          onSelectAll={selectAll}
        />
        <TableBody>
          {employees.map(e => (
            <EmployeeRow
              key={e._id}
              employee={e}
              defaultRole={defaultRole}
              member={value.find(m => m.employee === e._id)}
              onSelect={toggleSelect}
              onSetRole={setRole}
            />
          ))}
        </TableBody>
      </Table>
      <Pagination total={employeesCount} page={page} perPage={limit} onChange={changePage} />
    </div>
  );
};

EmployeesTable.propTypes = {
  name: string.isRequired,
  value: arrayOf(string).isRequired,
  error: string,
  defaultRole: string,
  onChange: func.isRequired,
  formik: object.isRequired,
};
EmployeesTable.defaultProps = {
  error: null,
  defaultRole: null,
};

export default connect(EmployeesTable);
