import { Button, Drawer } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';
import BackButton from 'components/@home/drawers/BackButton';
import CloseButton from 'components/@home/drawers/CloseButton';
import { HrBotContext } from 'components/@home/drawers/HrBotDrawer/hrBotContext';
import HrBotTab from 'components/@home/drawers/HrBotDrawer/HrBotTab';
import Loading from 'components/common/Loading';
import KeyboardArrowRightIcon from 'mdi-react/KeyboardArrowRightIcon';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { HR_BOT_APP_ID } from 'services/constants';
import apps from 'store/app/entities/apps/action';
import bulkMessagings from 'store/app/entities/bulkMessaging/action';
import documentFormats from 'store/app/entities/documentFormats/action';
import hrBotDrawer from 'store/app/ui/drawers/hrBot/action';
import useActions from 'utils/useActions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '95vw',
      maxWidth: 1280,
    },
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  closeButtonContainer: {
    paddingTop: 20,
    paddingLeft: 20,
  },
  container: {
    flex: 1,
    display: 'flex',
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    margin: '20px 0 20px 0',
    height: 'auto',
  },
  paper: {
    fontSize: 20,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100vh',
    overflow: 'scroll',
  },
  gray: {
    color: theme.palette.secondary.main,
    fontWeight: 'normal',
  },
  navLink: {
    padding: 0,
  },
}));

const HrBotDrawer = () => {
  const isOpen = useSelector(state => state.drawers.hrBot.isOpen);
  const dispatch = useDispatch();
  const app = useSelector(state => state.apps.apps[HR_BOT_APP_ID]);
  const bulkMessaging = useSelector(state => state.bulkMessaging.bulkMessaging);
  const { formats, loaded: formatsLoaded } = useSelector(state => state.documentFormats);
  const classes = useStyles();
  const [files, setFiles] = useState(null);
  const [formatId, setFormatId] = useState(null);
  const [message, setMessage] = useState('');
  const [uploadedPct, setUploadedPct] = useState('');
  const format = useMemo(() => formats[formatId], [formats, formatId]);
  const [tabStack, setTabStack] = useState(['home']);
  const [currentFile, setCurrentFile] = useState(null);
  const [droppedFiles, setDroppedFiles] = useState({});
  const checkIds = useActions(bulkMessagings.checkIds);

  const navigateTo = useCallback(tab => {
    setTabStack(ts => [...ts, tab]);
  }, []);

  const tab = useMemo(() => tabStack[tabStack.length - 1], [tabStack]);

  useEffect(() => {
    if (isOpen) {
      setFiles(null);
      setFormatId(null);
      setMessage('');
      setUploadedPct('');
      setTabStack(['home']);
      setCurrentFile(null);
      setDroppedFiles({});
      dispatch(bulkMessagings.clear());
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    if (tabStack.length === 1) {
      setFiles(null);
      setFormatId(null);
      setMessage('');
      setUploadedPct('');
      setCurrentFile(null);
      setDroppedFiles({});
      dispatch(bulkMessagings.clear());
    }
  }, [tabStack.length]);

  const navigateBack = useCallback((howMany = 1) => {
    setTabStack(ts => {
      const newStack = ts.slice(0, typeof howMany === 'number' ? -howMany : -1);
      if (newStack.length === 1) {
        setFiles(null);
      }
      return newStack;
    });
  }, []);

  useEffect(() => {
    if (!app && isOpen) {
      dispatch(apps.findHrBot());
    }
  }, [app, dispatch, isOpen]);

  useEffect(() => {
    if (!formatsLoaded && isOpen) {
      dispatch(documentFormats.load());
    }
  }, [dispatch, formatsLoaded, isOpen]);

  useEffect(() => {
    setMessage(format?.defaultMessage || I18n.t('HrBot.defaultMessage'));
  }, [format?.defaultMessage, formatId]);

  const title = useMemo(
    () =>
      tabStack.map((t, idx) => {
        const titleStr = (
          <Typography
            key={t}
            display="inline"
            component="h1"
            variant="h5"
            className={classnames(classes.title, { [classes.gray]: idx < tabStack.length - 1 })}
          >
            {I18n.t(`HrBot.tabs.${!format && t === 'editTemplate' ? 'newTemplate' : t}.title`, {
              appName: app?.name,
              name: format?.name,
            })}
          </Typography>
        );
        return idx < tabStack.length - 1 ? (
          <>
            <Button
              variant="text"
              role="button"
              className={classes.navLink}
              onClick={() => navigateBack(tabStack.length - idx - 1)}
            >
              {titleStr}
            </Button>
            <KeyboardArrowRightIcon className={classes.gray} />
          </>
        ) : (
          titleStr
        );
      }),
    [app?.name, classes.gray, classes.navLink, classes.title, format, navigateBack, tabStack],
  );

  const closeDrawer = () => {
    setTabStack(['home']);
    setFiles(null);
    dispatch(hrBotDrawer.close());
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={closeDrawer}>
      <div className={classes.root}>
        <div className={classes.closeButtonContainer}>
          {tabStack.length === 1 ? (
            <CloseButton onClick={closeDrawer} />
          ) : (
            <BackButton onClick={navigateBack} />
          )}
        </div>
        <div className={classes.content} role="none">
          <Container component="main" className={classes.container} maxWidth={false}>
            <div className={classes.paper}>
              {app && formats && formatsLoaded ? (
                <>
                  <div className={classes.titleWrapper}>{title}</div>
                  <HrBotContext.Provider
                    value={{
                      bulkMessaging,
                      closeDrawer,
                      currentFile,
                      files,
                      format,
                      formatId,
                      formats,
                      message,
                      navigateBack,
                      navigateTo,
                      setCurrentFile,
                      setFiles,
                      setFormatId,
                      setMessage,
                      setUploadedPct,
                      tab,
                      uploadedPct,
                      checkIds,
                      droppedFiles,
                      setDroppedFiles,
                    }}
                  >
                    <HrBotTab tab={tab} />
                  </HrBotContext.Provider>
                </>
              ) : (
                <Loading />
              )}
            </div>
          </Container>
        </div>
      </div>
    </Drawer>
  );
};

export default memo(HrBotDrawer);
