import { Checkbox, MenuItem, Select, SvgIcon, TableCell, TableRow, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InviteIcon from 'assets/icons/invite.svg';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import { func, object, string } from 'prop-types';
import React, { memo, useCallback } from 'react';
import { I18n } from 'react-redux-i18n';
import employeeShape from 'shapes/employee';

const useStyles = makeStyles(theme => ({
  root: {
    border: 0,
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.ultraUltraLight,
    },
  },
  inactive: {
    color: theme.palette.secondary.main,
  },
  checkbox: {
    position: 'relative',
    paddingRight: 30,
  },
  icon: {
    width: 16,
    top: 12,
    position: 'absolute',
    left: 65,
    color: theme.palette.secondary.main,
  },
}));

const getName = e =>
  [e.firstName, e.surName].filter(v => v).join(' ') + (isEmpty(e.code) ? '' : ` - ${e.code}`);

const EmployeeRow = ({ employee, defaultRole, onSelect, onSetRole, member }) => {
  const classes = useStyles();

  const select = useCallback(() => {
    onSelect(employee._id);
  }, [onSelect, employee._id]);

  const inactiveStyle = useCallback(
    isInactive => {
      return isInactive ? classes.inactive : undefined;
    },
    [classes.inactive],
  );

  const onChangeRole = useCallback(
    e => {
      onSetRole(employee, e.target.value);
    },
    [onSetRole, employee],
  );

  const isInactive = !employee.isAcceptTerms;

  const role = member?.role;

  return (
    <TableRow hover className={classes.root} onClick={select} selected={!!member}>
      <TableCell padding="checkbox" className={classes.checkbox}>
        {isInactive && (
          <SvgIcon className={classes.icon}>
            <InviteIcon />
          </SvgIcon>
        )}
        <Checkbox color="primary" checked={!!member} />
      </TableCell>
      <TableCell className={inactiveStyle(isInactive)} padding="normal">
        <Tooltip title={getName(employee)} placement="top">
          <span>{truncate(getName(employee), { length: 18 })}</span>
        </Tooltip>
      </TableCell>
      <TableCell className={inactiveStyle(isInactive)}>
        <Tooltip title={employee.department} placement="top">
          <span>{truncate(employee.department, { length: 18 })}</span>
        </Tooltip>
      </TableCell>
      <TableCell className={inactiveStyle(isInactive)}>
        <Tooltip title={employee.jobTitle} placement="top">
          <span>{truncate(employee.jobTitle, { length: 18 })}</span>
        </Tooltip>
      </TableCell>
      <TableCell className={inactiveStyle(isInactive)}>{employee.location}</TableCell>
      <TableCell className={isInactive ? classes.inactive : classes.cell}>
        {!!member && (
          <Select
            MenuProps={{ onClick: e => e.stopPropagation() }}
            style={{ width: 100 }}
            value={role || defaultRole}
            defaultValue={defaultRole}
            onChange={onChangeRole}
          >
            {['member', 'admin', 'guest'].map(r => (
              <MenuItem key={r} value={r}>
                {I18n.t(`Roles.${r}`)}
              </MenuItem>
            ))}
          </Select>
        )}
      </TableCell>
    </TableRow>
  );
};

EmployeeRow.propTypes = {
  employee: employeeShape.isRequired,
  member: object.isRequired,
  defaultRole: string,
  onSelect: func.isRequired,
  onSetRole: func.isRequired,
};

EmployeeRow.defaultProps = {
  defaultRole: null,
};

export default memo(EmployeeRow);
