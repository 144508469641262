import { Button, FormControlLabel, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChatFlowCaption from 'components/@home/@settings/Tabs/ChatFlowCaption';
import ChatFlowParametersTable from 'components/@home/@settings/Tabs/ChatFlowParametersTable';
import chatFlowRouteFormik from 'components/@home/@settings/Tabs/chatFlowRouteFormik';
import ChatFlowSection from 'components/@home/@settings/Tabs/ChatFlowSection';
import connector from 'components/@home/@settings/Tabs/connector';
import MessageArray from 'components/@home/@settings/Tabs/MessageArray';
import withContext from 'components/@home/@settings/Tabs/withContext';
import FormikAutosuggest from 'components/formik/Autosuggest';
import FormikChipInput from 'components/formik/ChipInput';
import FormikRadioGroup from 'components/formik/RadioGroup';
import Switch from 'components/formik/Switch';
import TextField from 'components/formik/TextField';
import { Field } from 'formik';
import orderBy from 'lodash/orderBy';
import CloseIcon from 'mdi-react/CloseIcon';
import { func, object } from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { useLazyListTeamsQuery } from 'store/app/entities/TeamsSlice';
import loadOptionsLazyQuery from 'utils/loadOptionsLazyQuery';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
    height: 'calc(100vh - 64px)',
    borderLeft: `solid 1px ${theme.palette.secondary.extraLight}`,
  },
  title: {
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    color: theme.palette.primary.ultraDark,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  close: {
    color: theme.palette.primary.ultraDark,
  },
  body: {
    flexGrow: 1,
    overflow: 'auto',
    paddingBottom: 20,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  details: {
    flexDirection: 'column',
  },
  field: {
    marginTop: theme.spacing(2),
  },
  intentActions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  horizontal: {
    flexDirection: 'row',
    marginTop: -9,
    marginBottom: 9,
  },
  optionHorizontal: {
    marginRight: theme.spacing(2),
    paddingRight: theme.spacing(4),
  },
  switchLabel: {
    marginLeft: 0,
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
}));
const ChatFlowRoute = ({
  chatFlowIntents,
  context,
  flow,
  handleSubmit,
  setFieldValue,
  setSelectedIntent,
  setSelectedWebhook,
  teams,
  values: { _id, intent, transition, transitionType, enableWebhook, webhook, enableEmailTo },
  chatFlowWebhooks,
}) => {
  const classes = useStyles();
  const [triggerListTeamsQuery] = useLazyListTeamsQuery();

  if (!flow) {
    context.pop();
    return null;
  }
  if (intent._id === 'new') {
    context.push({
      type: 'intent',
      intent: 'new',
      route: _id,
      callback: setSelectedIntent,
    });
  }
  if (webhook._id === 'new') {
    context.push({
      type: 'webhook',
      webhook: 'new',
      route: _id,
      callback: setSelectedWebhook,
    });
  }
  const intentSuggestions = [
    { value: 'new', label: I18n.t('ChatFlowSettings.New intent') },
    ...orderBy(
      Object.values(chatFlowIntents).map(i => ({
        value: i._id,
        label: i.name,
      })),
      [i => i.label?.toLowerCase()],
    ),
  ];
  const webhookSuggestions = [
    { value: 'new', label: I18n.t('ChatFlowSettings.New webhook') },
    ...orderBy(
      Object.values(chatFlowWebhooks).map(i => ({
        value: i._id,
        label: i.name,
      })),
      [i => i.label?.toLowerCase()],
    ),
  ];
  const pageSuggestions = [
    { value: 'new', label: I18n.t('ChatFlowSettings.New page') },
    ...orderBy(
      flow.pages?.map(p => ({
        value: p._id,
        label: p.name,
      })) || [],
      [i => i.label?.toLowerCase()],
    ),
  ];

  const teamSuggestions = Object.values(teams).map(t => ({
    value: t._id,
    label: t.name,
  }));
  return (
    <form onSubmit={handleSubmit} className={classes.root}>
      <header className={classes.header}>
        <Typography size="small" className={classes.title}>
          {I18n.t('ChatFlowSettings.Route')}
        </Typography>
        <div>
          <IconButton
            className={classes.close}
            edge="end"
            aria-label="close"
            onClick={context.clear}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </header>
      <section className={classes.body}>
        <ChatFlowSection section="Intent" caption>
          <Field
            component={FormikAutosuggest}
            suggestions={intentSuggestions}
            placeholder={I18n.t('ChatFlowSettings.Any')}
            name="intent._id"
            isClearable
            clearValue={() => {
              setFieldValue('intent', {});
            }}
            className={classes.field}
          />
          {(intent?._id || 'new') !== 'new' && (
            <div className={classes.intentActions}>
              <Button
                variant="text"
                color="primary"
                onClick={() => context.push({ type: 'intent', intent: intent._id })}
              >
                {I18n.t('ChatFlowSettings.Edit intent')}
              </Button>
            </div>
          )}
        </ChatFlowSection>
        <ChatFlowSection section="Condition" caption>
          <Field
            className={classes.field}
            component={TextField}
            name="condition"
            placeholder={I18n.t('ChatFlowSettings.Condition')}
          />
        </ChatFlowSection>
        <ChatFlowSection section="Fulfillment" caption>
          <MessageArray field="replies" />
          <FormControlLabel
            class={classes.switchLabel}
            control={<Field component={Switch} size="small" name="enableWebhook" />}
            label={I18n.t('ChatFlowSettings.Enable webhook')}
          />
          {enableWebhook?.length > 0 && (
            <Field
              component={FormikAutosuggest}
              suggestions={webhookSuggestions}
              name="webhook._id"
              isClearable
              clearValue={() => {
                setFieldValue('webhook', {});
              }}
            />
          )}
          {enableWebhook?.length > 0 && (webhook?._id || 'new') !== 'new' && (
            <div className={classes.intentActions}>
              <Button
                variant="text"
                color="primary"
                onClick={() => context.push({ type: 'webhook', webhook: webhook._id })}
              >
                {I18n.t('ChatFlowSettings.Edit webhook')}
              </Button>
            </div>
          )}
        </ChatFlowSection>
        <ChatFlowSection section="Utterance parameter" caption>
          <Field
            className={classes.field}
            component={TextField}
            name="utteranceParameter"
            placeholder={I18n.t('ChatFlowSettings.Parameter name')}
          />
        </ChatFlowSection>
        <ChatFlowSection section="Output parameters">
          <ChatFlowParametersTable name="outputParameters" />
        </ChatFlowSection>
        <ChatFlowSection section="Transition">
          <Field
            type="radio"
            component={FormikRadioGroup}
            itemClassName={classes.optionHorizontal}
            groupClassName={classes.horizontal}
            name="transitionType"
            items={['page', 'handoff'].map(i => [
              i,
              I18n.t(`ChatFlowSettings.transitionTypes.${i}`),
            ])}
          />
          {transitionType === 'page' && (
            <>
              <ChatFlowCaption section="Transition" />
              <Field
                component={FormikAutosuggest}
                suggestions={pageSuggestions}
                placeholder={I18n.t('ChatFlowSettings.None')}
                name="transition._id"
                className={classes.field}
                isClearable
              />
              {transition?._id === 'new' && (
                <Field
                  className={classes.field}
                  component={TextField}
                  name="transition.name"
                  placeholder={I18n.t('ChatFlowSettings.Page name')}
                />
              )}
            </>
          )}
          {transitionType === 'handoff' && (
            <>
              <ChatFlowCaption section="TransitionHandoff" />
              <Field
                component={FormikAutosuggest}
                loadOptions={loadOptionsLazyQuery}
                additional={{ page: 1, trigger: triggerListTeamsQuery }}
                suggestions={teamSuggestions}
                placeholder={I18n.t('ChatFlowSettings.None')}
                name="handoffTo._id"
                className={classes.field}
                isClearable
              />
              <FormControlLabel
                class={classes.switchLabel}
                control={<Field component={Switch} size="small" name="enableEmailTo" />}
                label={I18n.t('ChatFlowSettings.Notify via email')}
              />
              {enableEmailTo.length > 0 && (
                <Field
                  component={FormikChipInput}
                  blurBehavior="add"
                  placeholder={I18n.t('ChatFlowSettings.Email')}
                  name="emailTo"
                />
              )}
            </>
          )}
        </ChatFlowSection>
      </section>
      <footer className={classes.footer}>
        <div />
        <Button variant="contained" color="primary" type="submit">
          {I18n.t('ChatFlowSettings.Save')}
        </Button>
      </footer>
    </form>
  );
};

ChatFlowRoute.propTypes = {
  flow: object.isRequired,
  values: object.isRequired,
  handleSubmit: func.isRequired,
  chatFlowIntents: object.isRequired,
  context: object.isRequired,
  setFieldValue: func.isRequired,
  setSelectedIntent: func.isRequired,
  setSelectedWebhook: func.isRequired,
  teams: object,
  chatFlowWebhooks: object.isRequired,
};

ChatFlowRoute.defaultProps = {
  teams: {},
};

export default connector(withContext(chatFlowRouteFormik(ChatFlowRoute)));
