import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import connector from 'components/@home/@settings/Tabs/connector';
import SectionBody from 'components/@home/@settings/Tabs/SectionBody';
import SectionTitle from 'components/@home/@settings/Tabs/SectionTitle';
import DeleteDialog from 'components/common/DeleteDialog';
import CheckCircleIcon from 'mdi-react/CheckCircleIcon';
import DeleteIcon from 'mdi-react/DeleteIcon';
import DotsVerticalIcon from 'mdi-react/DotsVerticalIcon';
import EditIcon from 'mdi-react/EditIcon';
import { bool, object, objectOf } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import appShape from 'shapes/appShape';
import companyShape from 'shapes/company';
import userShape from 'shapes/user';
import { invalidateEmployeesTags } from 'store/app/entities/EmployeesApiSlice';
import initialsFromUsername from 'utils/initialsFromUsername';
import openAppConfig from 'utils/openAppConfig';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'column',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  row: {
    border: 0,
    cursor: 'pointer',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.secondary.ultraUltraLight,
    },
  },
  ...[1, 2, 3, 4, 5, 6, 7, 8, 9].reduce(
    (acc, level) => ({
      ...acc,
      [`level${level}`]: {
        paddingLeft: 24 + level * 40,
      },
    }),
    {},
  ),
  team: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    fontSize: 28,
    width: 80,
    height: 80,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.secondary.light,
    marginBottom: theme.spacing(3),
  },
  noAvatar: {
    backgroundColor: theme.palette.primary.main,
  },
  teamName: {
    marginLeft: 10,
  },
  main: {
    color: theme.palette.primary.main,
  },
  right: {
    textAlign: 'right',
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    width: 400,
    display: 'flex',
    flexDirection: 'column',
    margin: '15px',
    position: 'relative',
  },
  content: {
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  description: {
    flex: '1 0 auto',
  },
  titleWrapper: {
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  title: {
    maxWidth: 280,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  check: {
    marginLeft: '.5ex',
    color: theme.palette.tertiary.main,
    background: theme.palette.background.paper,
    borderRadius: '50%',
  },
  cardDots: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
  installed: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: 16,
    top: 16,
    '& span': {
      marginLeft: 4,
    },
  },
  backGround: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: 55,
    width: '100%',
    backgroundColor: theme.palette.secondary.ultraLight,
  },
  danger: {
    color: theme.palette.danger,
  },
}));

const AppsSettings = ({ actions, apps, authUser, authCompany, isCompanyAdmin, isTeamAdmin }) => {
  const [appToDelete, setAppToDelete] = useState(null);
  const [appToUninstall, setAppToUninstall] = useState(null);
  const [menuData, setMenuData] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const getAnchorEl = () => menuData?.target;

  const closeMenu = () => {
    setMenuData(null);
  };

  const openMenu = (event, app) => {
    setMenuData({ target: event.target, app });
  };

  const installApp = (app, install = !app.installed, force = false) => {
    if (install || force) {
      actions.apps.install(app._id, install).then(() => {
        dispatch(invalidateEmployeesTags([{ type: 'Employee', _id: 'LIST' }]));
      });
    } else if (!install) {
      setAppToUninstall(app);
    }
  };

  const editApp = () => {
    actions.drawers.app.open(menuData?.app);
    setMenuData(null);
  };

  const deleteApp = () => {
    if (menuData) {
      setAppToDelete(menuData.app);
      setMenuData(null);
    }
  };

  const confirmDelete = () => {
    actions.apps.destroy(appToDelete._id).then(() => {
      dispatch(invalidateEmployeesTags([{ type: 'Employee', _id: 'LIST' }]));
    });
    setAppToDelete(null);
  };

  const confirmUninstall = () => {
    setAppToUninstall(null);
    installApp(appToUninstall, false, true);
  };

  const closeDialog = () => {
    setAppToDelete(null);
  };

  const closeUninstallDialog = () => {
    setAppToUninstall(null);
  };

  useEffect(() => {
    actions.apps.load();
  }, [actions.apps]);

  return (
    <div className={classes.root}>
      <SectionTitle title={I18n.t('SettingsMenu.apps')}>
        <Button onClick={editApp} variant="contained" color="primary">
          {I18n.t('AppsSettings.New')}
        </Button>
      </SectionTitle>
      <SectionBody>
        <div className={classes.grid}>
          {Object.entries(apps).map(([key, app]) => (
            <Card elevation={3} key={key} className={classes.card}>
              <CardContent className={classes.content}>
                <div className={classes.backGround} />
                {app.installed ? (
                  <div className={classes.installed}>
                    <CheckCircleIcon className={classes.check} />
                    <Typography variant="caption" component="span">
                      {I18n.t('AppsSettings.installed')}
                    </Typography>
                  </div>
                ) : null}
                {app.creator === authUser._id || app.company === authCompany._id ? (
                  <div className={classes.cardDots}>
                    <IconButton onClick={event => openMenu(event, app)} size="large">
                      <DotsVerticalIcon />
                    </IconButton>
                  </div>
                ) : null}
                <Avatar
                  aria-label="avatar"
                  src={app.avatar}
                  className={classNames([classes.avatar, { [classes.noAvatar]: !app.avatar }])}
                >
                  {initialsFromUsername(app.name)}
                </Avatar>
                <Typography variant="h4" className={classes.titleWrapper}>
                  <span className={classes.title}>{app.name}</span>
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classes.description}
                >
                  {app.description}
                </Typography>
              </CardContent>
              {isTeamAdmin ? (
                <CardActions>
                  <Grid container spacing={2}>
                    {isCompanyAdmin && (
                      <Grid item>
                        <Button
                          className={app.installed ? classes.danger : ''}
                          variant="outlined"
                          color={app.installed ? 'inherit' : 'primary'}
                          onClick={() => installApp(app)}
                        >
                          {I18n.t(`AppsSettings.${app.installed ? 'un' : ''}install`)}
                        </Button>
                      </Grid>
                    )}
                    {app.installed && app.configPageUrl ? (
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={openAppConfig(actions, app._id)}
                        >
                          {I18n.t(`AppsSettings.Configure`)}
                        </Button>
                      </Grid>
                    ) : null}
                  </Grid>
                </CardActions>
              ) : null}
            </Card>
          ))}
        </div>
      </SectionBody>
      <Menu anchorEl={getAnchorEl} keepMounted open={!!menuData} onClose={closeMenu}>
        <MenuItem onClick={editApp}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <Typography variant="inherit">{I18n.t('AppsSettings.Edit')}</Typography>
        </MenuItem>
        <MenuItem onClick={deleteApp}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <Typography variant="inherit">{I18n.t('AppsSettings.Delete')}</Typography>
        </MenuItem>
      </Menu>
      <DeleteDialog
        typeBack={appToDelete?.name}
        message={I18n.t('AppsSettings.ConfirmDelete', { name: appToDelete?.name })}
        isOpen={appToDelete}
        onConfirm={confirmDelete}
        onClose={closeDialog}
      />
      <DeleteDialog
        confirmText={I18n.t('AppsSettings.uninstall')}
        typeBack={appToUninstall?.name}
        message={I18n.t('AppsSettings.ConfirmUninstallTitle', { name: appToUninstall?.name })}
        isOpen={appToUninstall}
        onConfirm={confirmUninstall}
        onClose={closeUninstallDialog}
      >
        <Typography>
          {I18n.t('AppsSettings.ConfirmUninstallBody', { name: appToUninstall?.name })}
        </Typography>
      </DeleteDialog>
    </div>
  );
};

AppsSettings.propTypes = {
  isCompanyAdmin: bool.isRequired,
  isTeamAdmin: bool.isRequired,
  authCompany: companyShape.isRequired,
  authUser: userShape.isRequired,
  actions: object.isRequired,
  apps: objectOf(appShape),
};

AppsSettings.defaultProps = {
  apps: [],
};

export default connector(AppsSettings);
