import { withFormik } from 'formik';
import { I18n } from 'react-redux-i18n';
import * as Yup from 'yup';

class TeamSubmit {
  constructor(values, formikBag) {
    this.formikBag = formikBag;
    this.values = values;
  }

  assembleForm = () => {
    const {
      membersAdded,
      membersRemoved,
      name,
      description,
      parent = null,
      isOptedOut,
      defaultRole,
    } = this.values;
    const { authEmployee, isSuperAdmin } = this.formikBag.props;

    const getMember = ({ employee, role }) => ({ employee, role: role || defaultRole });

    let users = membersAdded
      .filter(m => isOptedOut[0] !== 'on' || m.employee !== authEmployee._id)
      .map(getMember);
    let usersRemoved = membersRemoved;
    if (!isSuperAdmin && isOptedOut[0] !== 'on') {
      users = [...users, { employee: authEmployee._id, role: 'admin' }];
    } else {
      usersRemoved = [...usersRemoved];
      if (authEmployee?._id) {
        usersRemoved.push(authEmployee._id);
      }
    }

    const ret = { name, description, users, usersRemoved, isActive: 1, parent, defaultRole };
    return ret;
  };

  sendRequest = form => {
    const {
      props: { team, isEdit, actions },
    } = this.formikBag;
    if (isEdit) return actions.teams.update(team._id, form);
    return actions.teams.create(form);
  };

  displayErrors = err => {
    const { name } = this.values;
    const { setErrors } = this.formikBag;

    // eslint-disable-next-line no-console
    if (err.status === 409) {
      setErrors({
        server: I18n.t('Resource already exists', { resource: I18n.t('Resources.team'), name }),
      });
    } else if (err.type === 'body') {
      setErrors({ server: I18n.t('Something went wrong with sending data') });
    } else {
      setErrors({ server: I18n.t('Something went wrong with sending data') });
    }
  };
}

export default withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .max(100, () => I18n.t('TeamDrawer.Should not exceed', { count: 100 }))
      .trim()
      .required(() => I18n.t('TeamDrawer.Please enter name for the team')),

    description: Yup.string()
      .max(200, () => I18n.t('TeamDrawer.Should not exceed', { count: 200 }))
      .trim(),

    members: Yup.array(),
    defaultRole: Yup.string().required(() =>
      I18n.t('ValidationErrors.Required', { name: I18n.t('TeamDrawer.DefaultRoleField.label') }),
    ),
  }),

  mapPropsToValues: ({ team = {}, isEdit, authEmployee: ae, isSuperAdmin }) => {
    const isMember = team?.users?.some(e => e._id === ae?._id);
    return {
      _id: isEdit ? team._id : '',
      name: isEdit ? team.name : '',
      parent: isEdit ? team.parent : null,
      description: isEdit ? team.description : '',
      members: isEdit
        ? team.users
            ?.filter(_id => _id !== ae?._id)
            .map(u => ({ employee: u._id, role: u.role })) || []
        : [],
      optOutDisabled: isSuperAdmin || !ae?.isAdmin,
      isOptedOut: isSuperAdmin || (isEdit && !isMember) ? ['on'] : [],
      membersRemoved: [],
      membersAdded: [],
      defaultRole: isEdit ? team.defaultRole : '',
    };
  },

  handleSubmit: async (values, formikBag) => {
    const {
      props: { team, actions },
      setSubmitting,
    } = formikBag;

    const handler = new TeamSubmit(values, formikBag);

    const form = handler.assembleForm();

    try {
      await handler.sendRequest(form);

      setSubmitting(false);

      if (team?._id) {
        actions.invalidateTeamsTags([
          {
            type: 'Teams',
            id: team._id,
          },
        ]);
      }

      actions.drawers.team.close();
    } catch (err) {
      setSubmitting(false);
      handler.displayErrors(err);
    }
  },
  displayName: 'ChannelForm',
  enableReinitialize: true,
});
