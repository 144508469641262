/* eslint-disable jsx-a11y/label-has-associated-control,jsx-a11y/label-has-for */
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import FormikAutosuggest from 'components/formik/Autosuggest';
import Switch from 'components/formik/Switch';
import FormikTableField from 'components/formik/TableField';
import TextField from 'components/formik/TextField';
import { Field, useFormikContext } from 'formik';
import React, { memo, useCallback, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import employees from 'store/app/entities/employees/action';
import { useLazyListQuery } from 'store/app/entities/GroupsSlice';
import { useLazyListTeamsQuery } from 'store/app/entities/TeamsSlice';
import isCompanyAdminSelector from 'store/selectors/isCompanyAdmin';
import isSuperAdminSelector from 'store/selectors/isSuperAdmin';
import loadOptionsLazyQuery from 'utils/loadOptionsLazyQuery';
import useActions from 'utils/useActions';
import { useDebounceCallback } from 'utils/useDebounceCallback';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 20,
  },
  label: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 30,
  },
  switch: {
    marginTop: 15,
  },
  field: {
    padding: 5,
  },
}));

const perPage = 99;

const ChannelsControls = () => {
  const isCompanyAdmin = useSelector(isCompanyAdminSelector);
  const isSuperAdmin = useSelector(isSuperAdminSelector);
  const previewChannels = useActions(employees.previewChannels);
  const classes = useStyles();
  const { values } = useFormikContext();
  const [showPassword, setShowPassword] = useState(false);
  const [channelsLoading, setChannelsLoading] = useState(false);

  const teamsColumns = useMemo(
    () => [
      {
        key: 'name',
        disablePadding: false,
        label: I18n.t('EmployeeDrawer.TeamsTable.TeamName'),
        xsVisible: true,
      },
      {
        key: 'role',
        disablePadding: false,
        label: I18n.t('EmployeeDrawer.TeamsTable.Role'),
        xsVisible: true,
        type: 'dropdown',
        options: ['member', 'admin', 'guest'].map(value => ({
          value,
          label: I18n.t(`Roles.${value}`),
        })),
        defaultValue: val => val.defaultRole,
      },
      {
        key: 'delete',
        xsVisible: true,
        type: 'remove',
      },
    ],
    [],
  );

  const chatsColumns = useMemo(
    () => [
      {
        key: 'name',
        disablePadding: false,
        label: I18n.t('EmployeeDrawer.GroupsTable.GroupName'),
        xsVisible: true,
      },
      {
        key: 'groupRole',
        disablePadding: false,
        label: I18n.t('EmployeeDrawer.GroupsTable.Role'),
        xsVisible: true,
        type: 'dropdown',
        options: ['member', 'writer', 'admin'].map(value => ({
          value,
          label: I18n.t(`Roles.${value}`),
        })),
        defaultValue: 'member',
      },
      {
        key: 'remove',
        xsVisible: true,
        type: 'remove',
      },
    ],
    [],
  );

  const onGroupCheckboxChange = useCallback((formik, value, index) => {
    if (value[index].value.isAdmin && value[index].value.canWriteAsChannel !== null) {
      const newValue = [...value];
      newValue[index].value.canWriteAsChannel = null;
      formik.setFieldValue('channels', newValue);
    }
    if (!value[index].value.isAdmin && value[index].value.canWriteAsChannel === null) {
      const newValue = [...value];
      newValue[index].value.canWriteAsChannel = false;
      formik.setFieldValue('channels', newValue);
    }
  }, []);

  const handlePreviewChannels = useCallback(
    async (form, id, data) => {
      setChannelsLoading(true);
      const { value } = await previewChannels(id, data);
      form.setFieldValue(
        'channels',
        value
          .filter(c => !c.isDirect && !form.values.channelsRemoved?.includes(c._id))
          .map(c => ({
            label: c.name + (c.description ? ` - ${c.description}` : ''),
            value: { ...c, name: c.name + (c.description ? ` - ${c.description}` : ''), id: c._id },
          })),
      );
      setChannelsLoading(false);
    },
    [previewChannels],
  );
  const previewChannelsDebounced = useDebounceCallback(handlePreviewChannels, 500);

  const onTeamsChange = useCallback(
    (form, fieldName, fieldValue, fieldRemovedValue, fieldAddedValue) => {
      const directChannels = form.values.channels
        .filter(c => !(c.value.fromTeams?.length > 0))
        .map(({ value: { _id, groupRole } }) => ({
          _id,
          canWriteAsChannel: groupRole === 'writer' ? 1 : 0,
          isAdmin: groupRole === 'admin' ? 1 : 0,
        }));

      previewChannelsDebounced(form, form.values._id, {
        teams: fieldAddedValue.map(({ value: { _id, role = 'member', name } }) => ({
          _id,
          role,
          name,
        })),
        teamsRemoved: fieldRemovedValue,
        channels: directChannels,
      });
    },
    [previewChannelsDebounced],
  );

  const onTeamCheckboxChange = useCallback((formik, value) => {
    formik.setFieldValue('teams', value);
  }, []);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(v => !v);
  }, []);

  const handleMouseDownPassword = useCallback(event => {
    event.preventDefault();
  }, []);

  const [triggerListGroupsQuery] = useLazyListQuery();
  const [triggerListTeamsQuery] = useLazyListTeamsQuery();

  const loadChannels = useCallback(
    async (search, loadedOptions, additional) => {
      const page = additional?.page || 1;
      const result = await triggerListGroupsQuery({ search, page, perPage, admin: 1 }).unwrap();
      return {
        options: result.groups
          .filter(channel => values.channels?.every(c => c.value.id !== channel._id))
          .map(channel => ({
            label: channel.name + (channel.description ? ` - ${channel.description}` : ''),
            value: {
              id: channel._id,
              name: channel.name + (channel.description ? ` - ${channel.description}` : ''),
            },
          })),
        hasMore: result.totalCount > (page + 1) * perPage,
        additional: { page: page + 1 },
      };
    },
    [triggerListGroupsQuery, values.channels],
  );

  return (
    <div className={classes.root}>
      <Typography variant="caption">
        {I18n.t('EmployeeDrawer.GroupsChannelsPermissions')}
      </Typography>
      <div className={classes.form}>
        <Grid container>
          {isCompanyAdmin ? (
            <>
              <Grid item xs={2} className={classes.label}>
                <Typography>{I18n.t('EmployeeDrawer.CompanyAdmin')}</Typography>
              </Grid>
              <Grid item xs={10}>
                <div className={classes.switch}>
                  <Field component={Switch} name="isAdmin" multiple={false} />
                </div>
              </Grid>
            </>
          ) : null}
          {isSuperAdmin ? (
            <>
              <Grid item xs={2} className={classes.label}>
                <Typography>{I18n.t('EmployeeDrawer.LoginType')}</Typography>
              </Grid>
              <Grid item xs={10}>
                <div className={classes.switch}>
                  <Field
                    className={classes.field}
                    component={FormikAutosuggest}
                    name="loginType"
                    suggestions={['sms', 'password'].map(value => ({
                      label: value,
                      value,
                    }))}
                  />
                </div>
              </Grid>
              {values.loginType === 'password' && (
                <>
                  <Grid item xs={2} className={classes.label}>
                    <Typography>{I18n.t('EmployeeDrawer.NewPassword')}</Typography>
                  </Grid>
                  <Grid item xs={10}>
                    <div className={classes.switch}>
                      <Field
                        component={TextField}
                        className={classes.field}
                        type={showPassword ? 'text' : 'password'}
                        id="new-password"
                        autoComplete="new-password"
                        name="newPassword"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </Grid>
                </>
              )}
            </>
          ) : null}
          <>
            <Grid item xs={2} className={classes.label}>
              <Typography>
                {`${I18n.t('EmployeeDrawer.Teams')}${isCompanyAdmin ? '' : '*'}`}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Field
                component={FormikTableField}
                loadOptions={loadOptionsLazyQuery}
                additional={{
                  page: 1,
                  exclude: values.teams,
                  trigger: triggerListTeamsQuery,
                  perPage,
                }}
                onChange={onTeamsChange}
                columns={teamsColumns}
                onCheckboxChange={onTeamCheckboxChange}
                buttonText={I18n.t('EmployeeDrawer.Add team')}
                placeholder={I18n.t('EmployeeDrawer.Start typing')}
                name="teams"
              />
            </Grid>
          </>
          <Grid item xs={2} className={classes.label}>
            <Typography>{I18n.t('EmployeeDrawer.Channels')}</Typography>
          </Grid>
          <Grid item xs={10}>
            {channelsLoading ? (
              <ContentLoader style={{ flex: '0 0 auto' }} width={670} viewBox="0 0 420 150">
                <rect x="0" y="16" rx="6" ry="6" width="334" height="24" />
                <rect x="340" y="16" rx="6" ry="6" width="60" height="24" />
                <rect x="0" y="62" rx="6" ry="6" width="400" height="80" />
              </ContentLoader>
            ) : (
              <Field
                component={FormikTableField}
                loadOptions={loadChannels}
                additional={{ page: 1 }}
                removeDisabled={value => value.fromTeams?.length > 0}
                disabledTooltip={I18n.t('EmployeeDrawer.GroupsTable.DisabledTooltip')}
                columns={chatsColumns}
                onCheckboxChange={onGroupCheckboxChange}
                buttonText={I18n.t('EmployeeDrawer.Add channel')}
                placeholder={I18n.t('EmployeeDrawer.Start typing')}
                name="channels"
              />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default memo(ChannelsControls);
