/* eslint-disable no-console,no-nested-ternary,no-param-reassign,no-mixed-operators */
import { withFormik } from 'formik';
import { I18n } from 'react-redux-i18n';
import Employee from 'services/api/Employee';
import Invite from 'services/api/Invite';
import s from 'utils/seconds';
import * as Yup from 'yup';

class EmployeeSubmit {
  constructor(values, formikBag) {
    this.values = values;
    this.formikBag = formikBag;
  }

  createForm = values => ({
    jobTitle: values.jobTitle,
    department: values.department,
    email: values.email,
    phone: values.phone,
    location: values.location,
    tags: values.tags,
    isAdmin: values.isAdmin[0] === 'on',
    firstName: values.firstName,
    surName: values.lastName,
    code: values.code,
    id: values.id,
    channels: values.channelsAdded.map(({ value: { id, groupRole } }) => ({
      _id: id,
      canWriteAsChannel: groupRole === 'writer' ? 1 : 0,
      isAdmin: groupRole === 'admin' ? 1 : 0,
    })),
    channelsRemoved: values.channelsRemoved,
    teams: values.teamsAdded?.map(({ value: { _id, role, name } }) => ({
      _id,
      role: role || 'member',
      name,
    })),
    teamsRemoved: values.teamsRemoved,
    reportsTo: values.reportsTo,
    division: values.division,
    notes: values.notes,
  });

  sendRequest = async form => {
    const { employee, actions } = this.formikBag.props;
    const { update, create } = actions.employeeApi;

    const isEdit = !!employee;

    const response = await (isEdit ? update(employee._id, form) : create(form));
    if (response.error) {
      const error = new Error(response.error.data.message);
      error.data = response.error.data;
      error.status = response.error.status;
      throw error;
    }
    return response.data.data.employee;
  };

  showErrors = error => {
    const { setErrors } = this.formikBag;
    if (error.status === 409) {
      setErrors({
        server: I18n.t('Employee with field already exists', {
          value: (typeof error.data.key === 'string' ? [error.data.key] : error.data.key)
            .map(k =>
              typeof this.values[k] !== 'undefined'
                ? `${I18n.t(`Fields.${k}`)} ${this.values[k]}`
                : null,
            )
            .filter(v => v)
            .join(', '),
        }),
      });
    } else {
      try {
        setErrors({ server: error.data.data.error });
      } catch (serverError) {
        setErrors({ server: I18n.t('Something wrong with server response') });
      }
    }
  };

  showAvatarError = error => {
    const { setErrors } = this.formikBag;
    console.error(error);
    setErrors({ server: I18n.t('Picture was not uploaded for some reason') });
  };

  uploadAvatar = async (employee, avatar) => {
    const { actions } = this.formikBag.props;

    if (!avatar) return;

    try {
      const employeeWithAvatar = await Employee.uploadAvatar(employee._id, avatar);
      actions.employees.modify(employee._id, { avatar: employeeWithAvatar.avatar });
    } catch (error) {
      this.showAvatarError(error);
    }
  };
}

const formik = withFormik({
  validateOnChange: false,
  validateOnBlur: false,
  validationSchema: Yup.object().shape({
    allowAnonymousEmployees: Yup.boolean(),
    firstName: Yup.string()
      .trim()
      .when('allowAnonymousEmployees', {
        is: true,
        otherwise: Yup.string().required(
          () =>
            `${I18n.t('EmployeeDrawer.Please enter your')} ${I18n.t(
              'EmployeeDrawer.First name',
            ).toLowerCase()}`,
        ),
      }),
    lastName: Yup.string()
      .trim()
      .when('allowAnonymousEmployees', {
        is: true,
        otherwise: Yup.string().required(
          () =>
            `${I18n.t('EmployeeDrawer.Please enter your')} ${I18n.t(
              'EmployeeDrawer.Last name',
            ).toLowerCase()}`,
        ),
      }),
    phone: Yup.string()
      .test('phone-too-short', I18n.t('EmployeeDrawer.Phone number is too short'), phone => {
        return !phone || (phone.startsWith('+34') ? phone.length >= 12 : phone.length >= 8);
      })
      .trim(),
    email: Yup.string().trim().email(),
    code: Yup.string().trim(),
    id: Yup.string().trim(),
    jobTitle: Yup.string()
      .trim()
      .required(
        () =>
          `${I18n.t('EmployeeDrawer.Please enter your')} ${I18n.t(
            'EmployeeDrawer.Job title',
          ).toLowerCase()}`,
      ),
    department: Yup.string()
      .trim()
      .required(
        () =>
          `${I18n.t('EmployeeDrawer.Please enter your')} ${I18n.t(
            'EmployeeDrawer.Department',
          ).toLowerCase()}`,
      ),
    location: Yup.string().trim(),
    reportsTo: Yup.string().trim(),
    tags: Yup.array(),
    channels: Yup.array(),
    channelsRemoved: Yup.array(),
    teams: Yup.array().when('isCompanyAdmin', {
      is: true,
      then: Yup.array(),
      otherwise: Yup.array().min(
        1,
        () =>
          `${I18n.t('EmployeeDrawer.Please enter your')} ${I18n.t(
            'EmployeeDrawer.Teams',
          ).toLowerCase()}`,
      ),
    }),
    teamsRemoved: Yup.array(),
    division: Yup.string().when('companyDivisions', (d, schema) =>
      d?.length > 0 ? schema.required() : schema.transform(() => undefined),
    ),
  }),

  mapPropsToValues: ({
    employee,
    isCompanyAdmin,
    authCompany: { allowAnonymousEmployees, language, timeZone, divisions: companyDivisions },
  }) => {
    const H_9AM = s('9h');
    const H_5PM = s('17h');

    const defaultDay = active => (active ? [{ from: H_9AM, to: H_5PM }] : []);

    const WEEK_DAYS = Array.from({ length: 5 }, () => defaultDay(true));
    const WEEKEND = Array.from({ length: 2 }, () => defaultDay(false));
    const MON_FRI = WEEK_DAYS.concat(WEEKEND);

    const getLabel = channel =>
      channel.name + (channel.description ? ` - ${channel.description}` : '');

    if (employee) {
      return {
        _id: employee._id,
        isCompanyAdmin: !!isCompanyAdmin,
        allowAnonymousEmployees: !!allowAnonymousEmployees,
        invite: [],
        avatar: employee.avatar || '',
        firstName: employee.firstName?.replace(/^\*\*\*\*\*$/, ''),
        lastName: employee.surName,
        phone: employee.phone || '',
        email: employee.email || '',
        code: employee.code || '',
        id: employee.id || '',
        jobTitle: employee.jobTitle || '',
        department: employee.department || '',
        location: employee.location || '',
        reportsTo: employee.reportsTo || '',
        language: employee.settings?.language || language || 'es',
        timeZone: employee.settings?.timeZone || timeZone || 'Europe/Madrid',
        isTrackingTime: employee.settings?.isTrackingTime ? ['on'] : [],
        workingDays: employee.settings?.workingDays || MON_FRI,
        channels:
          employee.channels?.map(channel => ({
            label: getLabel(channel),
            value: {
              id: channel._id,
              name: getLabel(channel),
              groupRole: channel.groupRole,
              isAdmin: !!channel.isAdmin,
              canWriteAsChannel: channel.isAdmin ? null : !!channel.canWriteAsChannel,
              fromTeams: channel.fromTeams,
            },
          })) || [],
        channelsRemoved: [],
        channelsAdded: [],
        teams:
          employee.teams
            .filter(t => t.name)
            .sort((t1, t2) => t1.name.localeCompare(t2.name))
            .map(team => ({
              label: team.name,
              value: {
                _id: team._id,
                name: team.name,
                role: team.role,
              },
            })) || [],
        teamsRemoved: [],
        teamsAdded: [],
        tags: employee.tags || [],
        isAdmin: employee.isAdmin ? ['on'] : [],
        loading: !!employee.loading,
        division: employee.division,
        companyDivisions,
        userId: employee.user?._id,
        loginType: employee.user?.loginType || 'sms',
        newPassword: '',
        currentPassword: '',
        notes: employee.notes,
      };
    }

    return {
      isCompanyAdmin: !!isCompanyAdmin,
      allowAnonymousEmployees: !!allowAnonymousEmployees,
      inviteDisabled: true,
      invite: [],
      avatar: {},
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      code: '',
      id: '',
      jobTitle: '',
      department: '',
      location: '',
      reportsTo: '',
      language: language || 'es',
      timeZone: timeZone || 'Europe/Madrid',
      isTrackingTime: [],
      workingDays: MON_FRI,
      channels: [],
      channelsRemoved: [],
      channelsAdded: [],
      teams: [],
      teamsRemoved: [],
      teamsAdded: [],
      tags: [],
      isAdmin: [],
      isLoading: false,
      companyDivisions,
    };
  },

  handleSubmit: async (values, formikBag) => {
    const { setSubmitting, props } = formikBag;
    const { actions } = props;
    const submit = new EmployeeSubmit(values, formikBag);

    try {
      if (
        values.userId &&
        ((values.loginType === 'sms' && props.employee?.user?.loginType === 'password') ||
          (values.newPassword && values.currentPassword))
      ) {
        await actions.user.updatePassword(
          values.currentPassword,
          values.userId,
          values.newPassword,
          values.loginType,
        );
      }

      const form = submit.createForm(values);

      const newEmployee = await submit.sendRequest(form);

      actions.employees.updateSettings(newEmployee._id, {
        ...newEmployee.settings,
        language: values.language,
        timeZone: values.timeZone,
        isTrackingTime: values.isTrackingTime[0] === 'on',
        workingDays: values.workingDays,
      });

      submit.uploadAvatar(newEmployee, values.avatar);

      if (values.invite[0] === 'on') Invite.send(newEmployee._id);

      setSubmitting(false);

      actions.employeeDrawer.close();
    } catch (error) {
      submit.showErrors(error);
      setSubmitting(false);
    }
  },
  displayName: 'EmployeeForm',
  enableReinitialize: true,
});

export default formik;
